import { observer } from 'mobx-react-lite';
import React, { CSSProperties, Fragment, useEffect } from 'react';
import { ISelectItem } from '../../../../architecture/interfaces/select/ISelectItem';
import { useGetIcon } from '../../../customHooks/useGetIcon';
import { useSelectListHandler } from '../../../customHooks/useSelectListHandler';
import Overlay from '../../../layout/Overlay';
import Button from '../../Button';
import SearchField from '../SearchField';
import CreatorDisplayRow from './CreatorDisplayRow';
import CreatorSearchField from './CreatorSearchField';
import SelectItem from './SelectItem';

export interface ISelectProps {
  id?: string;
  items: ISelectItem[];
  replaceSpecialCharacters?: boolean;
  triggerLabel: string;
  placeholder?: string;
  creator?: boolean;
  clear?: boolean;
  closeOnSelect?: boolean;
  search?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  openUpwards?: boolean;
  className?: string;
  dropdownHeight?: number;
  selectHandler: (title: string, id?: string) => void;
  inputValidator?: (input: string) => boolean;
}
const Select: React.FC<ISelectProps> = ({
  id,
  items,
  triggerLabel,
  creator,
  clear = false,
  search,
  placeholder,
  replaceSpecialCharacters,
  closeOnSelect,
  disabled,
  fullWidth,
  openUpwards,
  className,
  dropdownHeight,
  selectHandler,
  inputValidator,
}) => {
  useEffect(() => {
    if (search && creator)
      throw new Error(
        'Your search field can either be a simple search field or a creator search field, not both.'
      );
  }, []);

  const getIcon = useGetIcon();

  const DROPDOWN_HEIGHT = dropdownHeight ? dropdownHeight : 250;

  const {
    activeItem,
    filteredItems,
    inputFilter,
    open,
    shouldOpenUpwards,
    inErrorState,
    handleDropdownOpen,
    resetDropdownState,
    handleInputChange,
    handleKeyDown,
    handleItemClick,
  } = useSelectListHandler({
    triggerLabel,
    items,
    selectHandler,
    inputValidator,
    closeOnSelect,
    openUpwards,
    maxHeight: DROPDOWN_HEIGHT,
  });

  const bottom = shouldOpenUpwards ? 0 : 'unset';
  const top = bottom !== 'unset' ? 'unset' : 0;

  const style: CSSProperties = {
    maxHeight: DROPDOWN_HEIGHT + 'px',
    bottom,
    top,
  };

  const isItemChosen = items.some((item) => item.title === triggerLabel);

  const renderControlField = () => {
    if (search)
      return (
        <SearchField
          value={inputFilter}
          placeholder={placeholder}
          replaceSpecialCharacters={replaceSpecialCharacters}
          changeHandler={handleInputChange}
          keyDownHandler={handleKeyDown}
        >
          {isItemChosen && (
            <i className={getIcon('undo')} onClick={() => selectHandler('')}></i>
          )}
        </SearchField>
      );

    if (creator || clear)
      return (
        <CreatorSearchField
          value={inputFilter}
          clear={clear}
          isItemChosen={isItemChosen}
          placeholder={placeholder}
          replaceSpecialCharacters={replaceSpecialCharacters}
          valueExists={!!items.find((item) => item.title === inputFilter)}
          changeHandler={handleInputChange}
          keyDownHandler={handleKeyDown}
          createHandler={selectHandler}
        />
      );

    return null;
  };

  const noItemFoundText = (() => {
    if (search) {
      return 'No match found';
    }

    return 'No items to show';
  })();

  const canAddNewItem =
    creator &&
    inputFilter.length > 0 &&
    items.every((item) => item.title !== inputFilter);

  const LABEL_LENGTH_LIMIT = fullWidth ? 40 : 25;
  const createButtonLabel = (() => {
    if (
      triggerLabel.length <= LABEL_LENGTH_LIMIT ||
      triggerLabel === placeholder ||
      fullWidth
    )
      return triggerLabel;

    return triggerLabel.substring(0, LABEL_LENGTH_LIMIT) + '...';
  })();

  return (
    <div
      id={id}
      className={`select-container  ${fullWidth ? 'full-width' : ''}`}
      data-error={inErrorState}
    >
      <Button
        type='button'
        className={`btn-select btn-round ${className ? className : ''}`}
        reverseContent
        icon={getIcon('caret_down')}
        content={createButtonLabel}
        disabled={disabled}
        clickHandler={handleDropdownOpen}
      />
      {open && (
        <Fragment>
          <Overlay clickHandler={resetDropdownState} transparent />
          <div className='select-container-open' style={style}>
            {renderControlField()}
            <div className='select-items-list'>
              {canAddNewItem && (
                <CreatorDisplayRow
                  text={inputFilter}
                  inErrorState={inErrorState}
                  createHandler={() => selectHandler(inputFilter)}
                />
              )}
              {filteredItems.length > 0 ? (
                filteredItems.map((item) => (
                  <SelectItem
                    key={item.title}
                    item={item}
                    isActive={activeItem && activeItem.title === item.title}
                    isChosen={triggerLabel === item.title}
                    clickHandler={handleItemClick}
                  />
                ))
              ) : (
                <div className='select-item no-match-found'>{noItemFoundText}</div>
              )}
            </div>
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default observer(Select);
