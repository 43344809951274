import { observer } from 'mobx-react-lite';
import React from 'react';
import { CommunicationDialogNode as CommunicationDialogNodeModel } from '../../../../models/DialogNodes/CommunicationDialogNode';
import { Text as TextModel } from '../../../../models/Utilities/Text';
import Messages from '../communicationNode/Messages';
import DialogNode from '../DialogNode';
import CommunicationNodeConfigurations from './CommunicationNodeConfigurations';

interface IProps {
  dialogNode: CommunicationDialogNodeModel;
  children?: React.ReactNode;
}
const CommunicationDialogNode: React.FC<IProps> = ({ dialogNode, children }) => {
  const addNewMessage = (message: TextModel) => {
    dialogNode.addMessage(message);
  };

  const removeMessage = (msg: TextModel) => {
    dialogNode.removeMessage(msg);
  };

  return (
    <DialogNode dialogNode={dialogNode}>
      <Messages
        messages={dialogNode.messages}
        newMessageHandler={addNewMessage}
        removeMessageHandler={removeMessage}
      />
      {children}
      <CommunicationNodeConfigurations dialogNode={dialogNode} />
    </DialogNode>
  );
};

export default observer(CommunicationDialogNode);
