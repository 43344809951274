import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { KnowledgebaseEntry } from '../../../models/Knowledgebase/KnowledgebaseEntry';
import Button from '../../common/Button';
import Modal from '../../common/modal/Modal';
import { useGetIcon } from '../../customHooks/useGetIcon';
import { useReadOnly } from '../../customHooks/useReadOnly';
import Attachments from '../attachments/Attachments';
import AudioPlaybackIcon from './AudioPlaybackIcon';
import AttachmentButton from './components/AttachmentButton';

interface IProps {
  entry: KnowledgebaseEntry;
}

const Actions: React.FC<IProps> = ({ entry }) => {
  const getIcon = useGetIcon();
  const { knowledgebaseReadOnlyMode } = useReadOnly();
  const [openAttachements, setOpenAttachments] = useState(false);

  const resetModal = () => {
    if (entry.areAttachmentsValid()) {
      setOpenAttachments(false);
    }
  };

  const activeSpeechMarkupClass = entry.answer.ssml ? 'active-icon' : '';

  const handleSsml = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    entry.isSpeechMarkupDisplayed = true;
  };

  const handleText = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    entry.isSpeechMarkupDisplayed = false;
  };

  return (
    <div className='cell-wrapper cell-wrapper-actions'>
      <div className='actions'>
        {entry.isSpeechMarkupDisplayed ? (
          <Button
            className='btn-borderless'
            icon={`${getIcon('font')} ${activeSpeechMarkupClass}`}
            disabled={knowledgebaseReadOnlyMode}
            clickHandler={handleText}
          />
        ) : (
          <Button
            tooltipPosition='top'
            disabled={knowledgebaseReadOnlyMode}
            tooltip={entry.answer.ssml ? 'Speech markup exists' : 'Speech markup exists'}
            className='btn-borderless'
            icon={`${getIcon('sound')} ${activeSpeechMarkupClass}`}
            clickHandler={handleSsml}
          />
        )}
        <AudioPlaybackIcon entry={entry} />
        {entry.state !== 'Deleted' && (
          <>
            {entry.state !== 'Added' && (
              <AttachmentButton entry={entry} setOpenAttachments={setOpenAttachments} />
            )}
            <Button
              className='btn-remove btn-borderless'
              icon={getIcon('remove')}
              disabled={knowledgebaseReadOnlyMode}
              clickHandler={() => entry.remove()}
            />
          </>
        )}
        {entry.state === 'Deleted' && (
          <Button
            className='btn-remove btn-borderless'
            icon={getIcon('undo')}
            disabled={knowledgebaseReadOnlyMode}
            clickHandler={() => entry.revert()}
          />
        )}
      </div>
      <Modal manuallyTriggered={openAttachements} closeHandler={resetModal}>
        <Attachments entry={entry} resetModal={resetModal} />
      </Modal>
    </div>
  );
};

export default observer(Actions);
