import { observer } from 'mobx-react-lite';
import createPanZoom from 'panzoom';
import React, { useContext, useEffect, useRef } from 'react';
import { DialogBlock as DialogBlockModel } from '../../models/DialogBlocks/DialogBlock';
import rootStore from '../../stores/rootStore';
import DraggableBlock from '../dialogElements/blocks/DraggableBlock';

interface IProps {
  blocks: DialogBlockModel[];
}
const DialogBlockList: React.FC<IProps> = ({ blocks }) => {
  const { uiStore, canvasStore } = useContext(rootStore);

  const blockListRef = useRef(null);

  useEffect(() => {
    const blockList = blockListRef.current as unknown as HTMLDivElement;

    function handlePanZoomTransform() {
      const transformValues =
        uiStore.dialogBlockPanZoomHandler.panZoomInstance!.getTransform();
      uiStore.dialogBlockPanZoomHandler.storedValues.transformX = transformValues.x;
      uiStore.dialogBlockPanZoomHandler.storedValues.transformY = transformValues.y;
      uiStore.dialogBlockPanZoomHandler.storedValues.scale = transformValues.scale;
      canvasStore.refresh();
    }

    uiStore.dialogBlockPanZoomHandler.panZoomInstance = createPanZoom(blockList, {
      maxZoom: 1.2,
      minZoom: 0.3,
      zoomSpeed: 0.4,
      initialZoom: uiStore.dialogBlockPanZoomHandler.storedValues.scale,
    });

    uiStore.dialogBlockPanZoomHandler.panZoomInstance.on(
      'transform',
      handlePanZoomTransform
    );

    uiStore.dialogBlockPanZoomHandler.panZoomInstance?.moveTo(
      uiStore.dialogBlockPanZoomHandler.storedValues.transformX,
      uiStore.dialogBlockPanZoomHandler.storedValues.transformY
    );

    return () => {
      uiStore.dialogBlockPanZoomHandler.panZoomInstance!.off(
        'transform',
        handlePanZoomTransform
      );
      uiStore.dialogBlockPanZoomHandler.panZoomInstance!.dispose();
      uiStore.dialogBlockPanZoomHandler.panZoomInstance = null;
    };
  }, []);

  return (
    <div ref={blockListRef} id='dialog-block-list-container'>
      {blocks.map((block) => (
        <DraggableBlock key={block.id} block={block} />
      ))}
    </div>
  );
};

export default observer(DialogBlockList);
