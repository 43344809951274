import { makeObservable, observable } from 'mobx';
import { v4 } from 'uuid';
import { HttpAgent } from '../../utility/HttpAgent';
import { Utilities } from './Utilities';

export class Text {
  id: string;
  value: string;
  ssml: string;

  constructor(value: string, ssml?: string, id?: string) {
    this.id = id ? id : v4();
    this.value = value;
    this.ssml = ssml ? ssml : '';

    makeObservable(this, {
      ssml: observable,
      value: observable,
    });
  }

  async getAudio(dialogId: string, useSsml: boolean): Promise<string | undefined> {
    try {
      const url = useSsml
        ? `/api/v2/proxy/${dialogId}/audio?ssml=${encodeURIComponent(this.ssml)}`
        : `/api/v2/proxy/${dialogId}/audio?text=${encodeURIComponent(this.value)}`;

      const response = await HttpAgent.requests.get(url);
      return response.audio;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  serialize(): ISerializedText {
    return {
      value: this.value,
      ssml: !Utilities.isEmpty(this.ssml) ? this.ssml : undefined,
    };
  }
}

export interface ISerializedText {
  value?: string;
  ssml?: string;
}
