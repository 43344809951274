import { DialogBlock } from '../models/DialogBlocks/DialogBlock';
import { Position } from '../models/Utilities/Position';
import { RootStore } from './rootStore';

class CanvasStore {
  private static instance: CanvasStore;
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    CanvasStore.instance = this;
  }

  get canvasElement() {
    return this.getOrDrawCanvasElement();
  }

  getNewDialogBlockInsertionPosition() {
    // Todo: It is definitely possible to calculate a much better insertion position. This is only a fast solution. So if you read this
    // and have time, improve it.

    const dialogBlockWidth = 300;
    const dialogBlockHeight = 138.5;
    const marginBetweenBlocks = 10;

    let referenceBlock = this.rootStore.blockStore.getMostTopBlock();

    if (!referenceBlock) {
      return new Position(20, 35);
    }

    const newBlockTop = referenceBlock!.position.y;
    const newBlockBottom =
      referenceBlock!.position.y + dialogBlockHeight + marginBetweenBlocks;
    let newBlockLeft =
      referenceBlock!.position.x + dialogBlockWidth + marginBetweenBlocks;
    let newBlockRight =
      referenceBlock!.position.x +
      dialogBlockWidth +
      marginBetweenBlocks +
      dialogBlockWidth;

    let collisionBlock: DialogBlock | undefined;
    do {
      collisionBlock = this.rootStore.blockStore.getByCondition(
        (x: DialogBlock) =>
          x.position.x >= newBlockLeft &&
          (x as DialogBlock).position.y <= newBlockRight &&
          (x as DialogBlock).position.y >= newBlockTop &&
          (x as DialogBlock).position.y <= newBlockBottom
      ) as DialogBlock;

      if (collisionBlock) {
        newBlockLeft =
          collisionBlock!.position.x + dialogBlockWidth + marginBetweenBlocks;
        newBlockRight =
          collisionBlock!.position.x +
          dialogBlockWidth +
          marginBetweenBlocks +
          dialogBlockWidth;
      }
    } while (collisionBlock);

    return new Position(newBlockLeft, newBlockTop);
  }

  updateAllBlockConnections() {
    for (let parent of this.rootStore.blockStore.allBlocks) {
      for (let child of parent.getChildren()) {
        this.updateBlockConnection(parent, child);
      }
    }
  }

  updateBlockConnection(parentBlock: DialogBlock, childBlock: DialogBlock) {
    this.drawOrUpdateCurvedLine(parentBlock, childBlock);
  }

  refresh() {
    this.updateAllBlockConnections();
    const scale = this.rootStore.uiStore.dialogBlockPanZoomHandler.storedValues.scale;
    const threshold = 0.5;
    document
      .querySelectorAll('path')
      .forEach((item) =>
        item.setAttribute(
          'stroke-width',
          (scale <= threshold ? threshold : scale).toString()
        )
      );
  }

  removeBlockConnection(parentBlock: DialogBlock, childBlock: DialogBlock) {
    const nodeConnectionId = `${parentBlock.id}->${childBlock.id}`;
    this.removeCurvedLine(nodeConnectionId);
  }

  private drawOrUpdateCurvedLine(sourceBlock: DialogBlock, targetBlock: DialogBlock) {
    const arrowId = `${sourceBlock.id}->${targetBlock.id}`;
    let curvedLine = document.getElementById(arrowId) as unknown as SVGPathElement;
    const scale = this.rootStore.uiStore.dialogBlockPanZoomHandler.storedValues.scale;
    const x1 = sourceBlock.bottomConnectorPosition.x;
    const y1 = sourceBlock.bottomConnectorPosition.y;
    const x2 = targetBlock.topConnectorPosition.x;
    const y2 = targetBlock.topConnectorPosition.y;

    const OFFSET_Y_BLOCK_BOTTOM = 35;
    const OFFSET_Y_BLOCK_TOP = 25; // 25 without description, 0 with description
    const isReferencingSelf = sourceBlock.id === targetBlock.id;

    const isTargetBelowSource = y1 - (sourceBlock.geometry.height * scale) / 2 < y2;

    const PATH_SETTINGS = {
      selfReferencingPath: {
        bottom: {
          x: x1 - 2,
          y: y1 - OFFSET_Y_BLOCK_BOTTOM,
        },
        top: {
          x: x2,
          y: y2 - OFFSET_Y_BLOCK_TOP,
        },
        curve1: x1 - 300 * scale,
        curve2: y1 + 80 * scale,
        curve3: x2 - 200 * scale,
        curve4: y2 - 250 * scale,
      },
      defaultPath: {
        bottom: {
          x: x1 + 6 * scale,
          y: y1 - OFFSET_Y_BLOCK_BOTTOM - 3,
        },
        top: {
          x: x2,
          y: y2 - OFFSET_Y_BLOCK_TOP,
        },
        curve1:
          x1 -
          Math.abs(
            y1 -
              this.rootStore.uiStore.dialogBlockPanZoomHandler.storedValues.transformY -
              (y2 -
                this.rootStore.uiStore.dialogBlockPanZoomHandler.storedValues.transformY)
          ),
        curve2: y1,
        curve3: x2,
        curve4: y2 - 140,
      },
    };

    const settings = isReferencingSelf
      ? PATH_SETTINGS.selfReferencingPath
      : PATH_SETTINGS.defaultPath;

    const isTargetAboveAndToTheRightOfSource = !isTargetBelowSource && x2 > x1;

    if (isTargetAboveAndToTheRightOfSource) {
      settings.curve1 = settings.curve1 + 500 * scale;
    }

    const pathIfAbove = `M ${settings.bottom.x} ${settings.bottom.y} C${settings.curve1} ${settings.curve2} ${settings.curve3} ${settings.curve4} ${settings.top.x} ${settings.top.y}`;

    const pathIfBelow = `M ${x1} ${y1 - OFFSET_Y_BLOCK_BOTTOM} C ${x1} ${y1 + 5} ${x2} ${
      y2 - 40
    } ${x2} ${y2 - OFFSET_Y_BLOCK_TOP}`;

    const path = isTargetBelowSource ? pathIfBelow : pathIfAbove;

    if (!curvedLine) {
      curvedLine = document.createElementNS('http://www.w3.org/2000/svg', 'path');
      curvedLine.setAttributeNS(null, 'id', arrowId);
      curvedLine.setAttributeNS(null, 'd', path);
      curvedLine.setAttributeNS(null, 'fill', 'none');
      curvedLine.setAttributeNS(null, 'marker-start', 'url(#dot)');
      curvedLine.setAttributeNS(null, 'marker-end', 'url(#arrow)');
      curvedLine.setAttributeNS(null, 'stroke', 'rgb(110, 110, 110)');
      this.canvasElement.appendChild(curvedLine);
    } else {
      curvedLine.setAttributeNS(null, 'd', path);
    }
  }

  private getOrDrawCanvasElement() {
    let svgCanvasElement: SVGSVGElement = document.getElementById(
      'svg-canvas'
    ) as unknown as SVGSVGElement;
    return svgCanvasElement;
  }

  private removeCurvedLine(id: string) {
    const curvedLine = document.getElementById(id) as
      | unknown
      | null as SVGPathElement | null;
    if (curvedLine === null) return;

    this.canvasElement.removeChild(curvedLine);
  }

  static getInstance() {
    if (!this.instance) {
      throw new Error('CanvasStore instance has not been initialized.');
    }

    return this.instance;
  }
}

export default CanvasStore;
