import { observer } from 'mobx-react-lite';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { ModalTypes } from '../../../architecture/enums/ModalTypes';
import { Bot } from '../../../models/Bot/Bot';
import { Dialog } from '../../../models/Bot/Dialog';
import { Utilities } from '../../../models/Utilities/Utilities';
import rootStore from '../../../stores/rootStore';
import Button from '../../common/Button';
import Modal from '../../common/modal/Modal';
import { useGetIcon } from '../../customHooks/useGetIcon';
import useModal from '../../customHooks/useModal';
import AddDialogForm from './AddDialogForm';
import DialogSettings from './DialogSettings';
import DialogTableRow from './DialogTableRow';
import Head from './Head';

interface IProps {
  bot: Bot;
}
const DialogTable: React.FC<IProps> = ({ bot }) => {
  const { dialogStore } = useContext(rootStore);

  const history = useHistory();
  const getIcon = useGetIcon();

  const { showModal } = useModal();
  const [modalOpen, setModalOpen] = useState(false);
  const [dialogSettings, setDialogSettings] = useState<Dialog | null>(null);
  const [editState, setEditState] = useState(false);

  useEffect(() => {
    setEditState(!!dialogSettings);
  }, [dialogSettings]);

  const handleActivate = async (dialog: Dialog) => {
    await dialogStore.activateDialog(bot.botId, dialog);
  };

  const handleRemoveDialog = async (dialog: Dialog) => {
    showModal(ModalTypes.DeleteElement, dialog);
  };

  const openDialog = async (dialog: Dialog) => {
    await dialogStore.setDialogToEdit(dialog);
    window.scrollTo({ top: 0, left: 0 });

    history.push(`/dialogs/${dialog.dialogId}/designer`);
  };

  const openDialogSettings = (dialog: Dialog) => {
    setDialogSettings(dialog);
    setModalOpen(true);
  };

  const updateDialogSettings = async () => {
    await dialogStore.updateDialogSettings(bot.botId, dialogSettings!);

    resetModal();
  };

  const resetModal = () => {
    setModalOpen(false);
    setDialogSettings(null);
  };

  return (
    <Fragment>
      {Utilities.isEmpty(bot.dialogs) ? (
        <p style={{ width: '100%', textAlign: 'center', fontStyle: 'italic' }}>
          <strong>{bot.name}</strong> has no dialogs yet. Start your work by adding a new
          one!
        </p>
      ) : (
        <table className='table'>
          <Head
            headers={['Name', 'Description', 'Last Editor', 'Modified at']}
            withControlColumn
          />
          <tbody>
            {bot.dialogs.map((dialog) => (
              <DialogTableRow
                key={dialog.dialogId}
                dialog={dialog}
                beingEdited={
                  dialogStore.currentlyEditedDialog?.dialogId === dialog.dialogId
                }
                openDialogSettings={() => openDialogSettings(dialog)}
                openDialog={() => openDialog(dialog)}
                handleActivate={() => handleActivate(dialog)}
                handleRemoveDialog={() => handleRemoveDialog(dialog)}
              />
            ))}
          </tbody>
        </table>
      )}
      <div className='add-dialog-btn-container'>
        {!bot.usesKnowledgebase && (
          <Button
            className='btn-borderless btn-add-minimal'
            content='Add New Dialog'
            icon={getIcon('add')}
            clickHandler={() => setModalOpen(true)}
          />
        )}
      </div>
      <Modal manuallyTriggered={modalOpen} closeHandler={resetModal}>
        {editState ? (
          <DialogSettings
            bot={bot}
            dialog={dialogSettings!}
            resetModal={resetModal}
            updateDialog={updateDialogSettings}
          />
        ) : (
          <AddDialogForm bot={bot} resetModal={resetModal} />
        )}
      </Modal>
    </Fragment>
  );
};

export default observer(DialogTable);
