import { action, computed, makeAutoObservable, observable, runInAction } from 'mobx';
import { history } from '..';
import { DialogState } from '../architecture/enums/DialogState';
import {
  IBotOverview,
  IGetDialogResponse,
} from '../architecture/interfaces/HTTP/DialogParams';
import { Bot } from '../models/Bot/Bot';
import { Dialog } from '../models/Bot/Dialog';
import { DialogConnector } from '../models/Connectors/DialogConnector';
import { SubscriptionConnector } from '../models/Connectors/SubscriptionConnector';
import { DefaultDialogBlock } from '../models/DialogBlocks/DefaultDialogBlock';
import { Notification } from '../models/Utilities/Notification';
import { ISerializedStore } from './SerializationStore';
import { RootStore } from './rootStore';

export default class DialogStore {
  private static instance: DialogStore;

  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);

    DialogStore.instance = this;
  }

  @observable
  bots: Bot[] = [];

  // @observable
  // autoSavedDialogAvailable: boolean = false;

  @observable
  state: DialogState = DialogState.None;

  @computed
  getState(state: DialogState): boolean {
    return (this.state & state) === state;
  }

  @computed
  setState(state: DialogState) {
    this.state |= state;
  }

  @computed
  removeState(state: DialogState) {
    this.state &= ~state;
  }

  @observable
  currentlyEditedDialog: Dialog | null = null;

  currentDialogVersion: number | null | undefined = undefined;

  @computed
  getActiveDialog(bot: Bot) {
    return bot.dialogs.find((dialog) => dialog.isActive);
  }

  @observable
  currentlyEditedBot: Bot | null = null;

  @computed
  get isDialogSelected() {
    return !!this.currentlyEditedDialog;
  }

  @computed
  get isBotSelected() {
    return !!this.currentlyEditedBot;
  }

  @action
  async loadBots(force?: boolean) {
    try {
      if (!this.getState(DialogState.Initialized) || force) {
        if (!this.rootStore.subscriptionStore.selectedSubscription) {
          history.push('/settings');
          return;
        }

        this.setState(DialogState.BotsLoading);
        const res = await SubscriptionConnector.getBots(
          this.rootStore.subscriptionStore.selectedSubscription.id
        );
        runInAction(() => {
          this.bots = res.overview.map((botOverview: IBotOverview) =>
            this.createBotInstance(botOverview)
          );
        });
        this.removeState(DialogState.BotsLoading);
        this.setState(DialogState.Initialized);
      }
    } catch (error) {
      console.error('Error in loadBots()', error);
    }
  }

  @action
  async addNewDialog(
    botId: string,
    name: string,
    description: string,
    locale: string = 'de-DE'
  ) {
    const defaultBlock = new DefaultDialogBlock();
    defaultBlock.createDefaultNode();

    const structure: ISerializedStore = {
      dialogBlocks: [defaultBlock.serialize()],
      dialogNodes: defaultBlock.nodes.map((nodes) => nodes.serialize()),
      contextVariables: [],
      knowledgebase: {
        knowledgebaseEntries: [],
      },
    };

    await DialogConnector.add(botId, name, description, locale, structure);
    await this.loadBots(true);
  }

  @action
  async activateDialog(botId: string, dialog: Dialog) {
    try {
      await DialogConnector.activate(botId, dialog.dialogId);
      await this.loadBots(true);
    } catch (error) {
      console.error(error);
      new Notification({ text: 'Dialog activation failed', type: 'error' });
    }
  }

  @action
  async exportDialog() {
    try {
      if (!this.currentlyEditedDialog || !this.currentlyEditedBot) {
        return;
      }

      this.setState(DialogState.Exporting);

      const zipFile = await DialogConnector.export(
        this.currentlyEditedBot.botId,
        this.currentlyEditedDialog.dialogId
      );

      const file = new Blob([zipFile], { type: 'application/zip' });

      const a = document.createElement('a');
      a.href = URL.createObjectURL(file);
      a.download = `${new Date().toISOString().split('T')[0]}-Lailo-${
        this.currentlyEditedDialog.dialogId
      }.lailo`;
      a.click();

      this.removeState(DialogState.Exporting);
    } catch (error) {
      this.removeState(DialogState.Exporting);

      console.error(error);
      new Notification({ text: 'Dialog export failed', type: 'error' });
    }
  }

  @action
  async importDialog(file: File) {
    try {
      if (!this.currentlyEditedDialog || !this.currentlyEditedBot) {
        return;
      }

      this.setState(DialogState.Importing);

      await DialogConnector.import(
        this.currentlyEditedBot.botId,
        this.currentlyEditedDialog.dialogId,
        file
      );

      await this.rootStore.mediaStore.load(true);

      await this.reloadDialog();

      this.removeState(DialogState.Importing);
    } catch (error) {
      this.removeState(DialogState.Importing);
      console.error(error);
    }
  }

  @action
  async updateDialogSettings(botId: string, dialog: Dialog) {
    await DialogConnector.update(botId, dialog, false);
    await this.loadBots(true);
  }

  @action
  async updateDialogStructure(dialog: Dialog) {
    if (!this.currentlyEditedBot?.botId) {
      console.error('No bot selected');
      return;
    }

    await DialogConnector.update(this.currentlyEditedBot.botId, dialog);

    this.rootStore.appStore.setEditorStructureAtLoad(
      JSON.stringify(this.rootStore.serializationStore.serialize())
    );
  }

  @action
  async removeDialog(dialog: Dialog) {
    const bot = this.bots.find((bot) =>
      bot.dialogs.some((d) => d.dialogId === dialog.dialogId)
    );
    if (!bot) {
      console.error('Bot not found');
      return;
    }

    await DialogConnector.delete(bot.botId, dialog.dialogId);
    await this.loadBots(true);
  }

  @action
  async reloadDialog() {
    if (this.currentlyEditedDialog) {
      await this.setDialogToEdit(this.currentlyEditedDialog, null, true);
    }
  }

  @action
  async setDialogToEdit(
    dialog: Dialog,
    version: number | null | undefined = null,
    force: boolean = false
  ) {
    // Preventing another dialog to be opened while a previous one is being fetched.
    if (this.rootStore.uiStore.loading.isLoading) return;

    if (
      dialog.dialogId === this.currentlyEditedDialog?.dialogId &&
      version === this.currentDialogVersion &&
      !force
    ) {
      return;
    }

    // Setting the currently edited dialog temporarily to the chosen dialog instance.
    // Note: it does NOT contain an editor structure yet, that will be fetched during the next steps.
    // The point is to display a loading indicator in the table row of the chosen dialog while data is being fetched.
    this.currentlyEditedDialog = dialog;
    this.currentDialogVersion = version;

    const bot = this.bots.find((bot) => bot.dialogs.some((d) => d === dialog));
    if (!bot) {
      console.error('Bot could not be selected');
      return;
    }

    this.currentlyEditedBot = bot!;

    this.rootStore.uiStore.loading.targetName = dialog.dialogId;
    const savedDialog = await DialogConnector.get(bot.botId, dialog.dialogId, version);

    runInAction(() => {
      dialog.editorStructure = savedDialog.editorStructure;

      this.rootStore.appStore.populateStores(dialog.editorStructure);
    });

    // The Intent trigger is not used at the moment.
    // await this.rootStore.intentStore.load(dialog.dialogId);

    // Auto-save feature not supported at the moment.
    // Check if there is an auto-saved version of this dialog in the browser storage.
    // In that case, we prompt the user if he/she wants to restore the the dialog from storage.
    // if (this.newerStoredDialogExists(savedDialog.modified)) {
    //   this.autoSavedDialogAvailable = true;
    // }
  }

  // Auto-save feature not supported at the moment.
  @action
  async restoreDialogFromStorage() {
    // if (!this.currentlyEditedDialog) {
    //   return;
    // }
    // const storedDialog = this.rootStore.localStorageStore.getStoredDialog(
    //   this.currentlyEditedDialog.dialogId
    // );
    // if (!storedDialog) {
    //   return;
    // }
    // this.rootStore.serializationStore.clearStores();
    // this.rootStore.serializationStore.setupStoresFromJson(storedDialog.structure);
    // this.currentlyEditedDialog.editorStructure = JSON.stringify(storedDialog.structure);
    // this.autoSavedDialogAvailable = false;
  }

  // Auto-save feature not supported at the moment.
  @action
  private newerStoredDialogExists(savedDialogTimestamp: Date) {
    const storedDialog = this.rootStore.localStorageStore.getStoredDialog(
      this.currentlyEditedDialog!.dialogId
    );

    const savedDialogTimestampTime = new Date(savedDialogTimestamp).getTime();

    return (
      storedDialog && new Date(storedDialog.modified).getTime() > savedDialogTimestampTime
    );
  }

  getBotById(botId: string) {
    return this.bots.find((bot) => bot.botId === botId);
  }

  getBotOfDialog(dialog: Dialog) {
    return this.bots.find((bot) => bot.dialogs.includes(dialog));
  }

  setDialogById(dialogId: string, version: number | null | undefined = null) {
    this.bots.forEach((bot) => {
      const dialog = bot.dialogs.find((d) => d.dialogId === dialogId);
      if (dialog) {
        this.setDialogToEdit(dialog, version);
      }
    });
  }

  @action
  reset() {
    this.currentlyEditedDialog = null;
    this.rootStore.appStore.setEditorStructureAtLoad('');
  }

  private createBotInstance(botResponse: IBotOverview) {
    return new Bot(
      botResponse.botId,
      botResponse.name,
      new Date(botResponse.creationDate + 'Z'),
      botResponse.dialogs.map((dialog: IGetDialogResponse) =>
        this.createDialogInstance(dialog)
      ),
      botResponse.language,
      botResponse.usesKnowledgebase
    );
  }

  private createDialogInstance(dialogResponse: IGetDialogResponse) {
    return new Dialog(
      new Date(dialogResponse.created),
      dialogResponse.creator,
      dialogResponse.dialogId,
      dialogResponse.editorStructure,
      dialogResponse.lastEditor,
      dialogResponse.locale,
      dialogResponse.modified ? new Date(dialogResponse.modified) : null,
      dialogResponse.outdated ? new Date(dialogResponse.outdated) : null,
      dialogResponse.published ? new Date(dialogResponse.published) : null,
      dialogResponse.version,
      dialogResponse.versionDescription,
      dialogResponse.versionName
    );
  }

  static getInstance() {
    if (!this.instance) {
      throw new Error('DialogStore instance has not been initialized.');
    }

    return this.instance;
  }
}
