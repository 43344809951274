import {
  AddKnowledgebaseEntry,
  GetKnowledgebaseResponse,
  Source,
  UpdateKnowledebaseRequest,
  UpdateKnowledgebaseEntry,
} from '../../architecture/interfaces/HTTP/KnowledgebaseParams';
import KnowledgebaseStore from '../../stores/KnowledgebaseStore';
import { HttpAgent } from '../../utility/HttpAgent';
import { Notification } from '../Utilities/Notification';

export default class KnowledgebaseConnector {
  private static get knowledgebaseStore() {
    return KnowledgebaseStore.getInstance();
  }

  static async get(botId?: string, dialogId?: string): Promise<GetKnowledgebaseResponse> {
    return await HttpAgent.requests.get(
      `/Api/V2/Bots/${botId}/Dialogs/${dialogId}/Knowledgebase`
    );
  }

  static async getSources(
    botId: string | undefined,
    dialogId: string
  ): Promise<Source[]> {
    return await HttpAgent.requests.get(
      `/Api/V2/Bots/${botId}/Dialogs/${dialogId}/Knowledgebase/Sources`
    );
  }

  static async export(
    botId: string | undefined,
    dialogId: string | undefined,
    format: 'tsv' | 'excel'
  ): Promise<ArrayBuffer | undefined> {
    return await HttpAgent.requests.get(
      `/Api/V2/Bots/${botId}/Dialogs/${dialogId}/Knowledgebase/Export?format=${format}`,
      {
        responseType: 'arraybuffer',
      }
    );
  }

  static async importSource(
    botId: string | undefined,
    dialogId: string | undefined,
    body: FormData
  ): Promise<GetKnowledgebaseResponse> {
    return await HttpAgent.requests
      .post(`/Api/V2/Bots/${botId}/Dialogs/${dialogId}/Knowledgebase/Sources`, body)
      .catch((error) => {
        let text = null;
        switch (error.response.status) {
          case 400:
            text = 'Could not import source';
            break;
          default:
            break;
        }
        if (text) {
          new Notification({ text: text, type: 'error' });
        }
      });
  }

  static async removeSource(
    botId: string | undefined,
    dialogId: string | undefined,
    source: string
  ): Promise<GetKnowledgebaseResponse> {
    return await HttpAgent.requests.delete(
      `/Api/V2/Bots/${botId}/Dialogs/${dialogId}/Knowledgebase/Sources/${encodeURIComponent(
        source
      )}`
    );
  }

  static async update(
    botId?: string,
    dialogId?: string
  ): Promise<GetKnowledgebaseResponse> {
    const updatedEntries = this.getUpdatedKnowledgebaseEntries();
    const deletedEntries = this.getDeletedKnowledgebaseEntries();
    const addedEntries = this.getAddedKnowledgebaseEntries();

    if (
      updatedEntries.length === 0 &&
      deletedEntries.length === 0 &&
      addedEntries.length === 0
    ) {
      throw Error('Knowledgebase should not be updated with empty values.');
    }

    if (
      addedEntries.length !== 0 &&
      (addedEntries.some((entry) => entry.answer === '') ||
        addedEntries.some((entry) => entry.questions.length === 0))
    ) {
      throw Error('Knowledgebase entry should not have an empty answer or no questions');
    }

    const body: UpdateKnowledebaseRequest = {
      add: {
        entries: addedEntries,
      },
      update: {
        entries: updatedEntries,
      },
      delete: {
        ids: deletedEntries,
      },
    };

    return await HttpAgent.requests.put(
      `/Api/V2/Bots/${botId}/Dialogs/${dialogId}/Knowledgebase`,
      body
    );
  }

  private static getAddedKnowledgebaseEntries(): AddKnowledgebaseEntry[] {
    const list = this.knowledgebaseStore.activeKnowledgebaseEntries
      .filter((knowledgebaseEntry) => knowledgebaseEntry.state === 'Added')
      .map((knowledgebaseEntry) => ({
        answer: knowledgebaseEntry.answer.value,
        questions: knowledgebaseEntry.questions.map((question) => question.text.value),
      }));

    return list;
  }

  private static getUpdatedKnowledgebaseEntries(): UpdateKnowledgebaseEntry[] {
    let list: UpdateKnowledgebaseEntry[] = [];

    // Modified answers:
    this.knowledgebaseStore.activeKnowledgebaseEntries
      .filter((entry) => entry.state === 'Modified')
      .forEach((entry) => {
        if (!entry.id) return;

        const newEntry: UpdateKnowledgebaseEntry = {
          id: entry.id,
          answer: entry.answer.value,
          questions: entry.questions
            .filter((q) => q.state !== 'Initialized' && q.state !== 'Deleted')
            .map((q) => q.text.value),
        };

        list.push(newEntry);
      });

    // Added/Deleted/Modified questions:
    this.knowledgebaseStore.activeKnowledgebaseEntries
      .filter(
        (entry) =>
          entry.state === 'UpToDate' &&
          entry.questions.some((q) => q.state !== 'UpToDate')
      )
      .forEach((entry) => {
        if (!entry.id) return;

        const newEntry: UpdateKnowledgebaseEntry = {
          id: entry.id,
          answer: entry.answer.value,
          questions: entry.questions
            .filter((q) => q.state !== 'Initialized' && q.state !== 'Deleted')
            .map((q) => q.text.value),
        };

        list.push(newEntry);
      });

    return list;
  }

  private static getDeletedKnowledgebaseEntries(): number[] {
    return this.knowledgebaseStore.allKnowledgebaseEntries
      .filter(
        (knowledgebaseEntry) =>
          knowledgebaseEntry.state === 'Deleted' && knowledgebaseEntry.id !== undefined
      )
      .map((entry) => entry.id) as number[];
  }
}
