export enum DialogBlockTypes {
  DialogBlock = 'DialogBlock',
  DefaultDialogBlock = 'DefaultDialogBlock',
  KnowledgebaseBlock = 'KnowledgebaseBlock',
}

export enum DialogNodeTypes {
  MessageDialogNode = 'MessageDialogNode',
  NumberQuestionNode = 'NumberQuestionNode',
  TextQuestionNode = 'TextQuestionNode',
  DecisiveQuestionNode = 'DecisiveQuestionNode',
  ChoiceQuestionNode = 'ChoiceQuestionNode',
  AddressQuestionNode = 'AddressQuestionNode',
  DateTimeQuestionNode = 'DateTimeQuestionNode',
  EmailQuestionNode = 'EmailQuestionNode',
  NameQuestionNode = 'NameQuestionNode',
  PhoneQuestionNode = 'PhoneQuestionNode',
  InfoAdaptiveCardNode = 'InfoAdaptiveCardNode',
  QuestionAdaptiveCardNode = 'QuestionAdaptiveCardNode',
  AttachmentQuestionNode = 'AttachmentQuestionNode',
  ActionAdaptiveCardNode = 'ActionAdaptiveCardNode',
  RedirectDialogNode = 'RedirectDialogNode',
  EmailActionNode = 'EmailActionNode',
  ApiActionNode = 'ApiActionNode',
  RootNode = 'RootNode',
  KnowledgebaseNode = 'KnowledgebaseNode',
  ContextActionNode = 'ContextActionNode',
  DatasetActionNode = 'DatasetActionNode',
  Undefined = 'Undefined',
  EntityQuestionNode = 'EntityQuestionNode',
  StorageActionNode = 'StorageActionNode',
  ConfirmationActionNode = 'ConfirmationActionNode',
}

// Only used in Serialization Store to determine if a serialized node object is going to be a Question Node
export const QuestionNodeTypes = {
  NumberQuestionNode: 'NumberQuestionNode',
  TextQuestionNode: 'TextQuestionNode',
  DecisiveQuestionNode: 'DecisiveQuestionNode',
  ChoiceQuestionNode: 'ChoiceQuestionNode',
  AddressQuestionNode: 'AddressQuestionNode',
  EmailQuestionNode: 'EmailQuestionNode',
  NameQuestionNode: 'NameQuestionNode',
  PhoneQuestionNode: 'PhoneQuestionNode',
  DateTimeQuestionNode: 'DateTimeQuestionNode',
  EntityQuestionNode: 'EntityQuestionNode',
  AttachmentQuestionNode: 'AttachmentQuestionNode',
};

export const AdaptiveCardNodeTypes = {
  InfoAdaptiveCardNode: 'InfoAdaptiveCardNode',
  QuestionAdaptiveCardNode: 'QuestionAdaptiveCardNode',
  ActionAdaptiveCardNode: 'ActionAdaptiveCardNode',
};

//#region Currently unused objects
export const CommunicationNodeTypes = {
  MessageDialogNode: 'MessageDialogNode',
  ...QuestionNodeTypes,
  ...AdaptiveCardNodeTypes,
};

export const NonCommunicationNodeTypes = {
  RedirectDialogNode: 'RedirectDialogNode',
  RootNode: 'RootNode',
  KnowledgebaseNode: 'KnowledgebaseNode',
};

export const ActionNodeTypes = {
  EmailActionNode: 'EmailActionNode',
  ApiActionNode: 'ApiActionNode',
  ContextActionNode: 'ContextActionNode',
  DatasetActionNode: 'DatasetActionNode',
  StorageActionNode: 'StorageActionNode',
  ConfirmationActionNode: 'ConfirmationActionNode',
};

export enum DatasetNodeType {
  Undefined = 'Undefined',
  Create = 'Create',
  Update = 'Update',
}

//#endregion
