import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useLayoutEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { DatasetState } from '../../../architecture/enums/DatasetState';
import { DialogBlockTypes } from '../../../architecture/enums/DialogComponentType';
import { MediaState } from '../../../architecture/enums/MediaState';
import { ModalTypes } from '../../../architecture/enums/ModalTypes';
import { DialogBlock as DialogBlockModel } from '../../../models/DialogBlocks/DialogBlock';
import AuthContext from '../../../ms_authentication/context/AuthContext';
import rootStore from '../../../stores/rootStore';
import { HttpAgent } from '../../../utility/HttpAgent';
import Button from '../../common/Button';
import FloatingButtonGroup from '../../common/FloatingButtonGroup';
import { useGetIcon } from '../../customHooks/useGetIcon';
import useModal from '../../customHooks/useModal';
import DialogBlockList from '../DialogBlockList';
import LoadingPage from '../LoadingPage';

interface IParamsProps {
  dialogId?: string;
}

const BlockOverview = () => {
  const {
    blockStore,
    canvasStore,
    dialogStore,
    subscriptionStore,
    mediaStore,
    datasetStore,
  } = useContext(rootStore);

  const { authenticated, loading: userLoading } = useContext(AuthContext);
  const params: IParamsProps = useParams();
  const getIcon = useGetIcon();
  const { showModal } = useModal();

  const [isBeingDragged, setIsBeingDragged] = useState(false);

  const load = async () => {
    await Promise.all([dialogStore.loadBots(), datasetStore.load()]);
  };

  useLayoutEffect(() => {
    document.body.style.overflow = 'hidden';
    window.scrollTo({ top: 0, left: 0 });
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  useEffect(() => {
    if (!userLoading && authenticated) load();

    return HttpAgent.cancelRequest;
  }, [authenticated, userLoading]);

  const loadMediaFiles = async () => {
    await mediaStore.load();
  };

  useEffect(() => {
    canvasStore.refresh();
  }, [canvasStore]);

  useEffect(() => {
    loadMediaFiles();
  }, [subscriptionStore.selectedSubscription?.id]);

  useEffect(() => {
    if (
      !dialogStore.currentlyEditedDialog &&
      params.dialogId &&
      mediaStore.getState(MediaState.Initialized) &&
      datasetStore.getState(DatasetState.Initialized)
    ) {
      dialogStore.setDialogById(params?.dialogId);
    }
  }, [dialogStore.bots, params, mediaStore.allMediaItems, datasetStore.allDatasets]);

  return !dialogStore.currentlyEditedDialog ? (
    <LoadingPage />
  ) : (
    <div
      id='block-overview'
      style={{ cursor: isBeingDragged ? 'grabbing' : 'grab' }}
      onMouseDown={() => setIsBeingDragged(true)}
      onMouseUp={() => setIsBeingDragged(false)}
    >
      <DialogBlockList blocks={blockStore.allBlocks} />
      {dialogStore.currentlyEditedDialog.outdated && (
        <div className='inactive-info'>
          This Dialog is not active. You can edit it, but it will not be used when talking
          to the Bot.
        </div>
      )}
      <FloatingButtonGroup fixed={true} rounded={true}>
        <Button icon={getIcon('info')} className={'btn-question btn-circle'} />
        <Button
          icon={getIcon('remove')}
          tooltip='Remove all blocks'
          className={'btn-question btn-circle'}
          clickHandler={() => showModal(ModalTypes.PurgeBlocks)}
        />
        <Button
          icon={getIcon('robot')}
          clickHandler={() => blockStore.addKnowledgebaseBlock()}
          className={'btn-add btn-circle'}
          tooltip='Add Knowledgebase'
          tooltipPosition='left'
          disabled={
            !dialogStore.currentlyEditedBot?.usesKnowledgebase ||
            !dialogStore.currentlyEditedDialog ||
            blockStore.getByCondition(
              (block) => block.type === DialogBlockTypes.KnowledgebaseBlock
            ) !== undefined
          }
        />
        <Button
          icon={getIcon('add')}
          clickHandler={() => blockStore.add(new DialogBlockModel())}
          className={'btn-add btn-circle'}
          tooltip='New Dialog-Block'
          tooltipPosition='left'
          disabled={!dialogStore.currentlyEditedDialog}
        />
      </FloatingButtonGroup>
    </div>
  );
};

export default observer(BlockOverview);
