import { action, computed, makeAutoObservable, observable } from 'mobx';
import { Notification } from '../models/Utilities/Notification';
import { Utilities } from '../models/Utilities/Utilities';
import { RootStore } from './rootStore';

class AppStore {
  private static instance: AppStore;
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
    AppStore.instance = this;
  }

  @observable
  savingInProgress: boolean = false;

  @observable
  isInErrorState: boolean = false;

  @observable
  private editorStructureAtLoad: string = '';

  @computed
  get hasEditorStructureChanged() {
    return (
      this.editorStructureAtLoad !==
      JSON.stringify(this.rootStore.serializationStore.serialize())
    );
  }

  @action setEditorStructureAtLoad(structureAsString: string) {
    this.editorStructureAtLoad = structureAsString;
  }

  @action
  public populateStores(editorStructure: string) {
    if (Utilities.isEmpty(editorStructure)) {
      this.rootStore.blockStore.restoreDefaultBlock();
      this.setEditorStructureAtLoad(
        JSON.stringify(this.rootStore.serializationStore.serialize())
      );
      return;
    }

    const deserializedEditorStructure = JSON.parse(editorStructure);
    this.rootStore.serializationStore.setupStoresFromJson(deserializedEditorStructure);
    this.setEditorStructureAtLoad(editorStructure);
  }

  @action
  async save(): Promise<boolean> {
    let infoNotification = new Notification({
      text: 'Saving data...',
      type: 'info',
      autoRemove: false,
    });

    try {
      this.savingInProgress = true;

      if (!this.rootStore.dialogStore.currentlyEditedDialog?.dialogId) return false;

      // The intent trigger is not used at the moment.
      // if (this.rootStore.intentStore.shouldBeUpdated) {
      //   await this.rootStore.intentStore.update();
      // }

      if (this.rootStore.knowledgebaseStore.isActive) {
        await this.rootStore.knowledgebaseStore.update();
      }

      await this.rootStore.dialogStore.updateDialogStructure(
        this.rootStore.dialogStore.currentlyEditedDialog
      );

      infoNotification.remove();
      new Notification({
        text: 'Saved data',
        type: 'success',
      });

      this.savingInProgress = false;

      return true;
    } catch (error) {
      console.error(error);

      infoNotification.remove();
      new Notification({
        text: 'Saving data failed',
        type: 'error',
      });

      return false;
    }
  }

  @action
  async discardChanges() {
    this.populateStores(this.editorStructureAtLoad);
  }

  @action
  resetDialogSelection() {
    this.rootStore.serializationStore.clearStores();
    this.rootStore.dialogStore.reset();

    // The intent trigger is not used at the moment.
    // this.rootStore.intentStore.purge();
  }

  static getInstance() {
    if (!this.instance) {
      throw new Error('AppStore instance has not been initialized.');
    }

    return this.instance;
  }
}

export default AppStore;
