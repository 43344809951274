import { action, computed, makeObservable, observable } from 'mobx';
import { Text } from '../Utilities/Text';
import { State } from './State';

export class Question {
  private _text: Text;
  initialText: string;
  state: State;

  constructor(text?: Text, state?: State) {
    this._text = text ? text : new Text('');
    this.initialText = text?.value.toString() ?? '';
    this.state = state ?? 'UpToDate';

    makeObservable(this, {
      initialText: observable,
      state: observable,
      text: computed,
      setDeletedState: action,
    });
  }

  get text() {
    return this._text;
  }

  reset(): void {
    this._text.value = this.initialText;
    this.state = 'UpToDate';
  }

  setDeletedState() {
    switch (this.state) {
      case 'UpToDate':
      case 'Modified':
      case 'Initialized':
        this.state = 'Deleted';
        break;
      default:
        break;
    }
  }

  setQuestion(text: string) {
    this._text.value = text;
    if (this.initialText === '') {
      this.state = 'Added';
    } else if (this._text.value !== this.initialText) {
      this.state = 'Modified';
    } else if (this._text.value === this.initialText) {
      this.state = 'UpToDate';
    }
  }

  serialize() {
    return {
      text: this._text.serialize(),
    };
  }
}
