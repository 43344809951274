import { observer } from 'mobx-react-lite';
import React from 'react';
import { EntityNodeModelTypes } from '../../../../../architecture/enums/EntityNodeModelType';
import { EntityQuestionNode } from '../../../../../models/DialogNodes/QuestionNodes/QuestionNodeTypes/EntityQuestionNode';
import Select from '../../../../common/inputElements/select/Select';

interface IProps {
  dialogNode: EntityQuestionNode;
}

const SelectModel: React.FC<IProps> = ({ dialogNode }) => {
  return (
    <div className='model-area'>
      <h4>Which model would you like to use?</h4>
      <Select
        triggerLabel={dialogNode.model || EntityNodeModelTypes.Best}
        items={Object.entries(EntityNodeModelTypes).map(([key, value]) => ({
          title: value,
          id: key,
        }))}
        selectHandler={(value) => (dialogNode.model = value as EntityNodeModelTypes)}
        search={false}
        closeOnSelect
      />
    </div>
  );
};

export default observer(SelectModel);
