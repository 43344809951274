import { observer } from 'mobx-react-lite';
import React, { Fragment, useContext } from 'react';
import { ModalTypes } from '../../../architecture/enums/ModalTypes';
import { DialogBlock as DialogBlockModel } from '../../../models/DialogBlocks/DialogBlock';
import rootStore from '../../../stores/rootStore';
import { useGetIcon } from '../../customHooks/useGetIcon';
import useModal from '../../customHooks/useModal';
import TriggerTypeSelector from '../../dialogElements/triggers/selector/TriggerTypeSelector';
import Overlay from '../../layout/Overlay';
import Button from '../Button';
import ActionNodeTypeSelector from './templates/ActionNodeTypeSelector';
import ConfirmDelete from './templates/ConfirmDelete';
import DiscardKnowledgebaseChanges from './templates/DiscardKnowledgebaseChanges';
import MediaDetails from './templates/MediaDetails';
import MessageNodeTypeSelector from './templates/MessageNodeTypeSelector';
import PurgeBlocks from './templates/PurgeBlocks';
import PurgeNodes from './templates/PurgeNodes';
import QuestionNodeTypeSelector from './templates/QuestionNodeTypeSelector';
import RestoreStructure from './templates/RestoreStructure';

const PremadeTemplateModal: React.FC<{}> = () => {
  const { closeModal } = useModal();
  const { uiStore } = useContext(rootStore);
  const closeIcon = useGetIcon()('close');

  const createPremadeModalView = () => {
    switch (uiStore.modalState.activeModalType) {
      case ModalTypes.QuestionNodeTypeSelector:
        return <QuestionNodeTypeSelector />;
      case ModalTypes.DeleteElement:
        return <ConfirmDelete />;
      case ModalTypes.ActionNodeTypeSelector:
        return <ActionNodeTypeSelector />;
      case ModalTypes.MessageNodeTypeSelector:
        return <MessageNodeTypeSelector />;
      case ModalTypes.Trigger:
        return (
          <TriggerTypeSelector block={uiStore.modalState.element as DialogBlockModel} />
        );
      case ModalTypes.PurgeBlocks:
        return <PurgeBlocks />;
      case ModalTypes.PurgeNodes:
        return <PurgeNodes />;
      case ModalTypes.MediaDetails:
        return <MediaDetails />;
      case ModalTypes.DiscardKnowledgebaseChanges:
        return <DiscardKnowledgebaseChanges />;
      case ModalTypes.RestoreStructure:
        return <RestoreStructure />;
      default:
        return null;
    }
  };

  return (
    <Fragment>
      <Overlay
        clickHandler={closeModal}
        inactive={uiStore.modalState.activeModalType === ModalTypes.Trigger}
      />
      <div className='modal'>
        <Button
          className={'btn-circle btn-absolute-right-top btn-borderless'}
          icon={closeIcon}
          tooltip={uiStore.modalState.tooltip}
          tooltipPosition='bottom'
          disabled={uiStore.modalState.preventClosing}
          clickHandler={closeModal}
        />
        {createPremadeModalView()}
      </div>
    </Fragment>
  );
};

export default observer(PremadeTemplateModal);
