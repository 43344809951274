import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect } from 'react';
import { FILE_TYPES } from '../../../architecture/constants/Knowledgebase';
import { KnowledgebaseState } from '../../../architecture/enums/KnowledgebaseState';
import { Source } from '../../../architecture/interfaces/HTTP/KnowledgebaseParams';
import rootStore from '../../../stores/rootStore';
import Button from '../../common/Button';
import Upload from '../../common/inputElements/Upload';
import { useGetIcon } from '../../customHooks/useGetIcon';
import LoadingPage from '../../layout/LoadingPage';

interface IProps {
  resetModal: () => void;
}

const ImportExportFiles: React.FC<IProps> = ({ resetModal }) => {
  const { knowledgebaseStore, dialogStore } = useContext(rootStore);
  const getIcon = useGetIcon();

  useEffect(() => {
    if (dialogStore.currentlyEditedDialog?.dialogId) {
      knowledgebaseStore.loadSources();
    }
  }, []);

  const exportForExcel = async () => {
    const data = await knowledgebaseStore.export('excel');
    if (!data) return;

    downloadFile(data, 'xlsx');
  };

  const exportAsTSV = async () => {
    const data = await knowledgebaseStore.export('tsv');
    if (!data) return;

    downloadFile(data, 'tsv');
  };

  const downloadFile = (data: ArrayBuffer, format: 'tsv' | 'xlsx') => {
    const versionName = dialogStore.currentlyEditedDialog?.versionName;
    const filename = `${
      new Date().toISOString().split('.')[0] // ISO string without milliseconds and timezone
    }-Lailo-${versionName}.${format}`;

    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
  };

  const importSource = async (formData: FormData, sourceName?: string) => {
    knowledgebaseStore.bufferSourceForImport(formData, sourceName);
  };

  const removeSource = (source: Source | null) => {
    if (source) {
      knowledgebaseStore.markSourceForDeletion(source);
    }
  };

  const renderSources = () => {
    return knowledgebaseStore.sources.map((sourceFile, index) => {
      return (
        <div className='uploaded-file' key={index}>
          <div>
            <i className={`${getIcon('file')} uploaded-file-icon`}></i>
            {sourceFile.displayName}
          </div>
          <i
            className={`${getIcon('remove')} remove`}
            onClick={() => {
              removeSource(sourceFile);
            }}
          ></i>
        </div>
      );
    });
  };

  return (
    <div className='modal-default knowledgebase-modal'>
      <div className='header'>
        <h3>Import / Export TSV or EXCEL</h3>
      </div>
      <div className='attachments-container'>
        <div className='title'>
          You can add FAQs from the Internet and upload your own files which are roughly
          structured in questions and answers. After uploading they will be added to your
          bot's knowledge base.
        </div>
        <Upload
          icon={getIcon('add_alt')}
          content='Import File'
          upload={importSource}
          acceptFiles={FILE_TYPES}
        />
        {knowledgebaseStore.getState(KnowledgebaseState.SourcesLoading) ? (
          <LoadingPage fullScreen={false} showText={false} />
        ) : (
          <>
            {knowledgebaseStore.sources.length > 0 ? (
              <div className='files-container'>{renderSources()}</div>
            ) : (
              <div className='mt-2'>No files imported</div>
            )}
          </>
        )}

        <div className='export-buttons'>
          <Button
            content='Export for Excel'
            icon={getIcon('spreadsheet')}
            className='btn-inactive btn-round'
            clickHandler={exportForExcel}
            name='export-excel'
          />
          <Button
            content='Export as .TSV file'
            icon={getIcon('doc')}
            className='btn-inactive btn-round'
            clickHandler={exportAsTSV}
            name='export-tsv'
          />
        </div>
      </div>
      <div className='btn-container'>
        <Button
          content='Done'
          className='btn-primary btn-round'
          clickHandler={resetModal}
        />
      </div>
    </div>
  );
};

export default observer(ImportExportFiles);
