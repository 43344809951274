import { observer } from 'mobx-react-lite';
import React, { useContext, useRef, useState } from 'react';
import { KnowledgebaseEntry } from '../../../models/Knowledgebase/KnowledgebaseEntry';
import { Notification } from '../../../models/Utilities/Notification';
import { Utilities } from '../../../models/Utilities/Utilities';
import rootStore from '../../../stores/rootStore';
import Button from '../../common/Button';
import { useGetIcon } from '../../customHooks/useGetIcon';
import { useReadOnly } from '../../customHooks/useReadOnly';

interface IProps {
  entry: KnowledgebaseEntry;
}

enum PlaybackState {
  Idle,
  Loading,
  Playback,
  Pause,
}

const AudioPlaybackIcon: React.FC<IProps> = ({ entry }) => {
  const getIcon = useGetIcon();
  const { knowledgebaseReadOnlyMode } = useReadOnly();
  const { dialogStore } = useContext(rootStore);

  const audioRef = useRef<HTMLAudioElement | null>(null);

  const [playbackState, setPlaybackState] = useState<PlaybackState>(PlaybackState.Idle);

  const clickHandler = async (event: React.MouseEvent<HTMLButtonElement>) => {
    switch (playbackState) {
      case PlaybackState.Idle:
        await audioPlayback(event);
        break;
      case PlaybackState.Loading:
        break;
      case PlaybackState.Playback:
        if (audioRef.current !== null) {
          audioRef.current.pause();
          setPlaybackState(PlaybackState.Pause);
        }
        break;
      case PlaybackState.Pause:
        if (audioRef.current !== null) {
          audioRef.current.play();
          setPlaybackState(PlaybackState.Playback);
        }
        break;
    }
  };

  const audioPlayback = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setPlaybackState(PlaybackState.Loading);

    const dialogId = dialogStore.currentlyEditedDialog?.dialogId;
    if (!dialogId) {
      new Notification({ text: 'Audio playback is not possible', type: 'error' });
    }

    const audioFile = await entry.answer.getAudio(
      dialogId!,
      entry.isSpeechMarkupDisplayed
    );

    setPlaybackState(PlaybackState.Playback);

    if (audioFile) {
      const audio = document.createElement('audio');
      audio.src = audioFile;
      audioRef.current = audio;

      audio.addEventListener('ended', () => {
        setPlaybackState(PlaybackState.Idle);
      });

      audio.play();
    }
  };

  const isCurrentMessagePropertyEmpty = () => {
    if (entry.isSpeechMarkupDisplayed) {
      return Utilities.isEmpty(entry.answer.ssml);
    } else {
      return Utilities.isEmpty(entry.answer.value);
    }
  };

  const messageEmpty = isCurrentMessagePropertyEmpty();

  let icon = getIcon('play');
  if (playbackState === PlaybackState.Loading) {
    icon = `${getIcon('loading')} active-icon spin`;
  } else if (playbackState === PlaybackState.Playback) {
    icon = `${getIcon('pause')} active-icon`;
  } else if (playbackState === PlaybackState.Pause) {
    icon = `${getIcon('play')} active-icon`;
  }

  return (
    <Button
      tooltipPosition='top'
      disabled={messageEmpty || knowledgebaseReadOnlyMode}
      tooltip={
        entry.isSpeechMarkupDisplayed ? 'Play back speech markup' : 'Play back text'
      }
      className='btn-borderless'
      icon={icon}
      clickHandler={clickHandler}
    />
  );
};

export default observer(AudioPlaybackIcon);
