import { observer } from 'mobx-react-lite';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { CONVERSATION_HISTORY_SCROLL } from '../../../architecture/constants/LocalStorage';
import { NavbarMenu } from '../../../architecture/enums/Navbar';
import Logo from '../../../assets/lailo_logo_white_without_claim-4.png';
import rootStore from '../../../stores/rootStore';
import NavMenu from './NavMenu';
import NavigationButton from './components/NavigationButton';
import ProfileMenu from './components/ProfileMenu';
import AdminSubNavbar from './subNavbars/AdminSubNavbar';
import DialogsSubNavbar from './subNavbars/DialogsSubNavbar';
import StorageSubNavbar from './subNavbars/StorageSubNavbar';

const Navbar = () => {
  const { blockStore, dialogStore } = useContext(rootStore);
  const location = useLocation();

  const [activeNavbarMenu, setActiveNavbarMenu] = useState<NavbarMenu | undefined>(
    NavbarMenu.Bots
  );

  useEffect(() => {
    if (location.pathname === '/' || location.pathname.includes('dialogs')) {
      setActiveNavbarMenu(NavbarMenu.Bots);
    } else if (location.pathname.includes('media')) {
      setActiveNavbarMenu(NavbarMenu.Media);
    } else if (location.pathname.includes('admin')) {
      setActiveNavbarMenu(NavbarMenu.Admin);
    } else if (location.pathname.includes('dataset')) {
      setActiveNavbarMenu(NavbarMenu.Storage);
    } else if (
      location.pathname.includes('settings') ||
      location.pathname.includes('error')
    ) {
      setActiveNavbarMenu(undefined);
    } else if (!location.pathname.includes('history')) {
      localStorage.removeItem(CONVERSATION_HISTORY_SCROLL);
    }
  }, [location.pathname, dialogStore.currentlyEditedDialog]);

  return (
    <Fragment>
      <nav className='navbar'>
        <div className='navbar-logo-container'>
          <NavigationButton
            content={
              <div
                className='navbar-logo-pic'
                style={{
                  background: `url(${Logo}) no-repeat center center/contain`,
                }}
              ></div>
            }
            active={activeNavbarMenu === NavbarMenu.Bots}
            routeTo='/'
          />
          <div className='active-block-display'>{blockStore.selectedBlock?.title}</div>
        </div>
        <div className='navbar-navigation'>
          <NavMenu activeNavbarMenu={activeNavbarMenu} />
          <ProfileMenu />
        </div>
      </nav>
      {activeNavbarMenu === NavbarMenu.Bots && <DialogsSubNavbar />}
      {activeNavbarMenu === NavbarMenu.Admin && <AdminSubNavbar />}
      {activeNavbarMenu === NavbarMenu.Storage && <StorageSubNavbar />}
    </Fragment>
  );
};

export default observer(Navbar);
