import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { KnowledgebaseTriggerElement as KnowledgebaseTriggerElementModel } from '../../../../models/ConversationHistory/HistoryElement/TriggerHistoryElement/KnowledgebaseTriggerElement';
import { useGetIcon } from '../../../customHooks/useGetIcon';
import BaseHistoryElement from '../BaseHistoryElement';

interface IProps {
  element: KnowledgebaseTriggerElementModel;
}

const KnowledgebaseTriggerElement: React.FC<IProps> = ({ element }: IProps) => {
  const getIcon = useGetIcon();
  const score = element && element.score ? (element.score * 100).toFixed(0) : 0;
  const threshold =
    element && element.threshold ? (element.threshold * 100).toFixed(0) : 0;

  const [openAdditionalInfo, setOpenAdditionalInfo] = useState(false);

  return (
    <BaseHistoryElement
      element={element}
      openAdditionalInfo={openAdditionalInfo}
      icon={getIcon('robot')}
      setOpenAdditionalInfo={element.answer ? setOpenAdditionalInfo : undefined}
      title='Knowledgebase Trigger evaluated'
    >
      {element.triggered ? (
        <div className='card-paragraph'>
          <i className={getIcon('check')}> </i>
          <span> Triggered with a probability of {score}% </span>
        </div>
      ) : (
        <div className='card-paragraph'>
          <i className={getIcon('close_circle')}> </i>
          <span> Not triggered with a probablity of {score}%</span>
        </div>
      )}

      {openAdditionalInfo && (
        <>
          <div className='card-paragraph'>
            {(element.score || 0) * 100 < (element.threshold || 0) && (
              <div>
                Highest scored answer: <em>"{element.answer}"</em>
              </div>
            )}
            <div> Threshold: {threshold}% </div>
          </div>
        </>
      )}
    </BaseHistoryElement>
  );
};

export default observer(KnowledgebaseTriggerElement);
