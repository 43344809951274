import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { useHistory, useLocation } from 'react-router';
import AuthContext from '../../../../ms_authentication/context/AuthContext';
import Button from '../../../common/Button';
import { useGetIcon } from '../../../customHooks/useGetIcon';

const ProfileMenu = () => {
  const history = useHistory();
  const location = useLocation();
  const { user, logout, username } = useContext(AuthContext);
  const getIcon = useGetIcon();

  const goToSettingsPage = () => {
    history.push('/settings');
  };

  const getAcronym = () => {
    if (user?.idTokenClaims) {
      const givenName: string = (user.idTokenClaims as any).given_name;
      const surname: string = (user.idTokenClaims as any).family_name;

      if (givenName && surname) {
        return (
          givenName.substring(0, 1).toUpperCase() + surname.substring(0, 1).toUpperCase()
        );
      }

      return '';
    }
  };

  return (
    <div className='navbar-profile'>
      <div className='profile-icon'>
        <div className='profile-icon-circle'>{getAcronym()}</div>
      </div>
      <div className='profile-details'>
        <span> {username} </span>
        <div className='profile-options'>
          <Button
            icon={getIcon('config')}
            className={`btn-borderless sign-out ${
              location.pathname.includes('settings') && 'active'
            }`}
            content='Settings'
            clickHandler={goToSettingsPage}
          />
          <Button
            icon={getIcon('sign_out')}
            className='btn-borderless sign-out'
            content='Sign out'
            clickHandler={logout}
          />
        </div>
      </div>
    </div>
  );
};

export default observer(ProfileMenu);
