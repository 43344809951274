import { GERMAN_TEXTS } from './texts/deutsch';
import { ENGLISH_TEXTS } from './texts/english';
import { HUNGARIAN_TEXTS } from './texts/hungarian';

export interface ILanguageValues {
  DashboardTitle: string;
  NavbarTitle: string;
}

export type ILanguageImplementation = {
  [key: string]: ILanguageValues;
};

export const languages = {
  'en-US': {
    label: 'English',
    code: 'en-US',
    picture: '/not-pic-yet.jpg',
  },
  'de-DE': {
    label: 'Deutsch',
    code: 'de-DE',
    picture: '/not-pic-yet.jpg',
  },
  'hu-HU': {
    label: 'Magyar',
    code: 'hu-HU',
    picture: '/not-pic-yet.jpg',
  },
};

export const languageBundle: ILanguageImplementation = {
  ...GERMAN_TEXTS,
  ...ENGLISH_TEXTS,
  ...HUNGARIAN_TEXTS,
};
