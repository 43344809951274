export enum TriggerTypes {
  UserInput = 'UserInput',
  RegexInput = 'RegexInput',
  Context = 'Context',
  Event = 'Event',
  Combination = 'Combination',
  Node = 'Node',
  Intent = 'Intent',
  AI = 'AI',
}
