import { Trigger } from './Trigger';
import { ContextVariable } from '../ContextVariables/ContextVariable';
import { DialogBlock } from '../DialogBlocks/DialogBlock';
import { TriggerTypes } from '../../architecture/enums/TriggerTypes';

export class ContextTrigger extends Trigger {
  // ctx: ContextVariable;

  constructor() {
    super();
    this.type = TriggerTypes.Context;
  }
}
