import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import { SettingsState } from '../../../architecture/enums/ConfirmationSettingsState';
import { Notification } from '../../../models/Utilities/Notification';
import rootStore from '../../../stores/rootStore';
import Input from '../../common/Input';
import Modal from '../../common/modal/Modal';
import Confirm from '../../common/modal/templates/Confirm';
import { useGetIcon } from '../../customHooks/useGetIcon';
import SettingsInfoIcon from '../components/SettingsInfoIcon';

const BotSettings: React.FC = () => {
  const { botSettingsStore, dialogStore } = useContext(rootStore);
  const getIcon = useGetIcon();

  const [confirmModalOpened, setConfirmModalOpened] = useState(false);

  const load = async () => {
    await botSettingsStore.getSettings();
  };

  useEffect(() => {
    return () => {
      botSettingsStore.removeState(SettingsState.Initialized);
    };
  }, []);

  useEffect(() => {
    if (dialogStore?.currentlyEditedDialog?.dialogId) {
      load();
    }
  }, [dialogStore?.currentlyEditedDialog?.dialogId]);

  const botSecret = botSettingsStore.settings.botSettings.botSecret?.toUpperCase();
  const threshold = botSettingsStore.settings.botSettings.threshold;

  return (
    <div className='settings-container'>
      <div className='settings-content'>
        <div className='properties-wrapper'>
          <h3 className='title'>Bot</h3>
          <div className='input-group'>
            <div className='bot-settings-wrapper'>
              <label htmlFor='bot-secret'>
                Bot Secret
                <SettingsInfoIcon tooltip='The bot secret which will be used for the Lailo Avatar or other channels.' />
                <div className='input-button'>
                  <Input
                    className='bot-secret-input'
                    id='bot-secret'
                    disabled={true}
                    value={botSecret ?? ''}
                    changeHandler={() => {}}
                  />
                  <i
                    className={getIcon('copy')}
                    onClick={() => {
                      botSecret && navigator.clipboard.writeText(botSecret);
                      new Notification({
                        text: 'Bot Secret copied',
                        type: 'success',
                      });
                    }}
                  />
                  <i
                    className={getIcon('rotate')}
                    onClick={() => {
                      setConfirmModalOpened(true);
                    }}
                  />
                </div>
              </label>
            </div>
          </div>
        </div>
        <div className='properties-wrapper'>
          <h3 className='title'>Knowledgebase</h3>
          <div className='input-group'>
            <div className='bot-settings-wrapper'>
              <label htmlFor='threshold'>
                Threshold
                <SettingsInfoIcon tooltip='Set the threshold for the knowledgebase.' />
              </label>
              <div className='threshold-input'>
                <Input
                  id='threshold'
                  inputType='range'
                  min={0}
                  max={100}
                  value={threshold?.toString() ?? ''}
                  changeHandler={(value) => {
                    botSettingsStore.settings.botSettings.threshold = Number(value);
                  }}
                  disabled={threshold === null}
                />
                {threshold !== null && (
                  <span className='threshold-value'>{Math.round(threshold)}%</span>
                )}
              </div>
            </div>
          </div>
        </div>
        <Modal
          manuallyTriggered={confirmModalOpened}
          closeHandler={() => {
            setConfirmModalOpened(false);
          }}
        >
          <Confirm
            onCancel={() => {
              setConfirmModalOpened(false);
            }}
            onConfirmed={() => {
              setConfirmModalOpened(false);
              botSettingsStore.resetBotSecret();
            }}
          />
        </Modal>
      </div>
    </div>
  );
};

export default observer(BotSettings);
