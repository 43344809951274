import { Expose } from 'class-transformer';
import { makeObservable, observable } from 'mobx';
import { TriggerTypes } from '../../architecture/enums/TriggerTypes';
import {
  TransformIntoText,
  TransformIntoTextArray,
} from '../Utilities/Deserialization/Decorators';
import { ISerializedText, Text } from '../Utilities/Text';
import { Utilities } from '../Utilities/Utilities';
import { ISerializedTrigger, Trigger } from './Trigger';

export class AiTrigger extends Trigger {
  readonly type = TriggerTypes.AI;

  @Expose()
  @TransformIntoText
  intentDescription: Text = new Text('');

  @Expose()
  @TransformIntoTextArray
  exampleTopics: Text[] = [];

  constructor() {
    super();

    makeObservable(this, {
      intentDescription: observable,
      exampleTopics: observable,
    });
  }

  get isValid() {
    if (Utilities.isEmpty(this.intentDescription.value)) {
      return false;
    }

    if (Utilities.isEmpty(this.exampleTopics)) {
      return true;
    }

    return this.exampleTopics.every((topic) => topic.value !== '');
  }

  deleteExampleTopic(id: string) {
    this.exampleTopics = this.exampleTopics.filter((item) => item.id !== id);
  }

  addExampleTopic() {
    this.exampleTopics.push(new Text(''));
  }

  serialize(): ISerializedAiTrigger {
    return {
      ...super.serialize(),
      intentDescription: this.intentDescription,
      exampleTopics: this.exampleTopics.map((item) => item.serialize()),
    };
  }
}

export interface ISerializedAiTrigger extends ISerializedTrigger {
  intentDescription: ISerializedText;
  exampleTopics: ISerializedText[];
}
