import React from 'react';
import { TriggerTypes } from '../../../architecture/enums/TriggerTypes';
import { Trigger as TriggerModel } from '../../../models/Triggers/Trigger';
import { useGetIcon } from '../../customHooks/useGetIcon';

interface IProps {
  trigger?: TriggerModel;
  isKnowledgebaseBlock: boolean;
  clickHandler?: () => void;
}

const Bubble: React.FC<IProps> = ({ isKnowledgebaseBlock, trigger, clickHandler }) => {
  const getIcon = useGetIcon();

  const getTriggerIcon = () => {
    switch (trigger?.type) {
      case TriggerTypes.Event:
        return <i className={getIcon('event_trigger')}></i>;
      case TriggerTypes.UserInput:
        return <i className={getIcon('list_trigger')}></i>;
      case TriggerTypes.RegexInput:
        return <i className={getIcon('regex_trigger')}></i>;
      case TriggerTypes.Intent:
        return <i className={getIcon('intent_trigger')}></i>;
      case TriggerTypes.AI:
        return <i className={getIcon('brain')}></i>;
      default:
        break;
    }
  };

  return (
    <div className='trigger-bubble' onClick={clickHandler}>
      {trigger && !isKnowledgebaseBlock ? (
        getTriggerIcon()
      ) : (
        <i className={getIcon('robot')}></i>
      )}
    </div>
  );
};

export default Bubble;
