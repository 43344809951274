export enum NavbarMenu {
  Admin = 'Admin',
  Media = 'Media',
  Storage = 'Storage',
  Bots = 'Bots',
}

export enum AdminSubmenu {
  Subscriptions = 'Subscriptions',
  Bots = 'Bots',
  Users = 'Users',
  Billing = 'Billing',
  AvatarTextures = 'Textures',
}

export enum StorageSubmenu {
  Manage = 'Manage',
  Data = 'Data',
  Integrate = 'Integrate',
}

export enum DialogsSubmenu {
  Overview = 'Overview',
  History = 'History',
  Designer = 'Designer',
  Settings = 'Settings',
  Credits = 'Credits',
}
