import { Expose } from 'class-transformer';
import { makeObservable, observable } from 'mobx';
import { DialogNodeTypes } from '../../../architecture/enums/DialogComponentType';
import type { IContextVariable } from '../../../architecture/interfaces/contextVariables/IContextVariable';
import { DialogBlock } from '../../DialogBlocks/DialogBlock';
import {
  TransformIntoContextVariable,
  TransformIntoTextArray,
} from '../../Utilities/Deserialization/Decorators';
import { ISerializedText, Text } from '../../Utilities/Text';
import { ISerializedDialogNode } from '../BaseDialogNode';
import { ActionDialogNode } from './ActionDialogNode';

export class ConfirmationActionNode extends ActionDialogNode {
  readonly type: DialogNodeTypes = DialogNodeTypes.ConfirmationActionNode;

  @Expose({ name: 'contextVariableId' })
  @TransformIntoContextVariable
  contextVariable?: IContextVariable;

  @Expose()
  @TransformIntoTextArray
  messages: Text[];

  @Expose()
  annotation: string;

  constructor(dialogBlock: DialogBlock) {
    super(dialogBlock);
    this.title = 'Confirmation Action Node';
    this.messages = [new Text('', '')];
    this.annotation = '';

    makeObservable(this, {
      contextVariable: observable,
      messages: observable,
      annotation: observable,
    });
  }

  get isValid(): boolean {
    return !!this.contextVariable && !!this.annotation;
  }

  addMessage(text: string | Text, ssml?: string) {
    const message = text instanceof Text ? text : new Text(text, ssml);
    this.messages.push(message);
  }

  removeMessage(msg: Text) {
    this.messages = this.messages.filter((message) => msg !== message);
  }

  serialize(): ISerializedConfirmationNode {
    return {
      ...super.serialize(),
      annotation: this.annotation,
      contextVariableId: this.contextVariable?.id,
      messages: this.messages.map((msg) => msg.serialize()),
    };
  }
}

export interface ISerializedConfirmationNode extends ISerializedDialogNode {
  annotation: string;
  contextVariableId?: string;
  messages: ISerializedText[];
}
