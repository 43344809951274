import {
  IBot,
  IRefreshBotSecretResponse,
  IUpdateBotRequest,
} from '../../../architecture/interfaces/HTTP/AdminParams';
import { ICreateBotForm } from '../../../architecture/interfaces/admin/IBotForm';
import { HttpAgent } from '../../../utility/HttpAgent';
import { Bot } from '../../Admin/Bot';
import { Notification } from '../../Utilities/Notification';

export class BotConnector {
  static async refreshBotSecret(
    subscriptionId: string,
    botId: string
  ): Promise<IRefreshBotSecretResponse> {
    return await HttpAgent.requests.get(
      `/Api/V2/Admin/Subscriptions/${subscriptionId}/Bots/${botId}/BotSecret`
    );
  }

  static async getBySubscriptionId(subscriptionId: string): Promise<IBot[]> {
    return await HttpAgent.requests
      .get(`/Api/V2/Admin/Subscriptions/${subscriptionId}/Bots`)
      .catch((error) => {
        let text = null;
        switch (error.response.status) {
          case 400:
            text = 'No subscription id given';
            break;
          default:
            break;
        }
        if (text) {
          new Notification({ text: text, type: 'error' });
        }
      });
  }

  static async delete(subscriptionId: string, botId: string): Promise<void> {
    return await HttpAgent.requests
      .delete(`/Api/V2/Admin/Subscriptions/${subscriptionId}/Bots/${botId}`)
      .catch((error) => {
        let text = null;
        switch (error.response.status) {
          case 400:
            text = 'Bad request';
            break;
          default:
            break;
        }
        if (text) {
          new Notification({ text: text, type: 'error' });
        }
      });
  }

  static async update(
    subscriptionId: string,
    botId: string,
    request: IUpdateBotRequest
  ): Promise<void> {
    return await HttpAgent.requests
      .put(`/Api/V2/Admin/Subscriptions/${subscriptionId}/Bots/${botId}`, request)
      .catch((error) => {
        let text = null;
        switch (error.response.status) {
          case 400:
            text = 'Bad request';
            break;
          case 404:
            text = 'Bad request';
            break;
          default:
            break;
        }
        if (text) {
          new Notification({ text: text, type: 'error' });
        }
      });
  }

  static async create(subscriptionId: string, form: ICreateBotForm): Promise<Bot> {
    return await HttpAgent.requests
      .post(`/Api/V2/Admin/Subscriptions/${subscriptionId}/Bots`, {
        botName: form.botName,
        expiryDate: form.expiryDate,
        initialCredits: form.initialCredits !== 0 ? form.initialCredits : undefined,
        knowledgebaseResource: form.createKnowledgebase
          ? form.knowledgebaseResource
          : undefined,
        language: form.language,
        startDateTime: form.startDateTime,
        billingPlanId: form.billingPlanId,
        avatarTexture: form.createTexture ? form.avatarTexture : undefined,
      })
      .catch((error) => {
        let text = null;
        switch (error.response.status) {
          case 400:
            text = 'Bad request';
            break;
          default:
            break;
        }
        if (text) {
          new Notification({ text: text, type: 'error' });
        }
      });
  }
}
