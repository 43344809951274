import { ContextVariableInstanceTypes } from '../../../architecture/enums/ContextVariableInstanceTypes';
import {
  DialogBlockTypes,
  DialogNodeTypes,
} from '../../../architecture/enums/DialogComponentType';
import {
  ContextVariable,
  DatasetContextVariable,
  ListContextVariable,
} from '../../ContextVariables/ContextVariable';
import { SystemContextVariable } from '../../ContextVariables/SystemContextVariable';
import { DefaultDialogBlock } from '../../DialogBlocks/DefaultDialogBlock';
import { DialogBlock } from '../../DialogBlocks/DialogBlock';
import { KnowledgebaseBlock } from '../../DialogBlocks/KnowledgebaseBlock';
import { ActionAdaptiveCardNode } from '../../DialogNodes/ActionNodes/ActionAdaptiveCardNode';
import { ApiActionNode } from '../../DialogNodes/ActionNodes/ApiActionNode/ApiActionNode';
import { ConfirmationActionNode } from '../../DialogNodes/ActionNodes/ConfirmationActionNode';
import { ContextActionNode } from '../../DialogNodes/ActionNodes/ContextActionNode';
import { DatasetActionNode } from '../../DialogNodes/ActionNodes/DatasetActionNode';
import { EmailActionNode } from '../../DialogNodes/ActionNodes/EmailActionNode';
import { StorageActionNode } from '../../DialogNodes/ActionNodes/StorageActionNode';
import { KnowledgebaseNode } from '../../DialogNodes/KnowledgebaseNode';
import { InfoAdaptiveCardNode } from '../../DialogNodes/MessageNodes/InfoAdaptiveCardNode';
import { MessageDialogNode } from '../../DialogNodes/MessageNodes/MessageDialogNode';
import { AddressQuestionNode } from '../../DialogNodes/QuestionNodes/QuestionNodeTypes/AddressQuestionNode';
import { AttachmentQuestionNode } from '../../DialogNodes/QuestionNodes/QuestionNodeTypes/AttachmentQuestionNode';
import { ChoiceQuestionNode } from '../../DialogNodes/QuestionNodes/QuestionNodeTypes/ChoiceQuestionNode';
import { DateTimeQuestionNode } from '../../DialogNodes/QuestionNodes/QuestionNodeTypes/DateTimeQuestionNode';
import { DecisiveQuestionNode } from '../../DialogNodes/QuestionNodes/QuestionNodeTypes/DecisiveQuestionNode';
import { EmailQuestionNode } from '../../DialogNodes/QuestionNodes/QuestionNodeTypes/EmailQuestionNode';
import { EntityQuestionNode } from '../../DialogNodes/QuestionNodes/QuestionNodeTypes/EntityQuestionNode';
import { NameQuestionNode } from '../../DialogNodes/QuestionNodes/QuestionNodeTypes/NameQuestionNode';
import { NumberQuestionNode } from '../../DialogNodes/QuestionNodes/QuestionNodeTypes/NumberQuestionNode';
import { PhoneQuestionNode } from '../../DialogNodes/QuestionNodes/QuestionNodeTypes/PhoneQuestionNode';
import { QuestionAdaptiveCardNode } from '../../DialogNodes/QuestionNodes/QuestionNodeTypes/QuestionAdaptiveCardNode';
import { TextQuestionNode } from '../../DialogNodes/QuestionNodes/QuestionNodeTypes/TextQuestionNode';
import { RedirectDialogNode } from '../../DialogNodes/RedirectDialogNode';
import { RootNode } from '../../DialogNodes/RootNode';

export const CTX_INSTANCE_TYPE_MAPPING = {
  [ContextVariableInstanceTypes.ContextVariable]: ContextVariable,
  [ContextVariableInstanceTypes.DatasetContextVariable]: DatasetContextVariable,
  [ContextVariableInstanceTypes.ListContextVariable]: ListContextVariable,
  [ContextVariableInstanceTypes.SystemContextVariable]: SystemContextVariable,
};

export const NODE_CLASS_MAPPING = {
  [DialogNodeTypes.RootNode]: RootNode,
  [DialogNodeTypes.AddressQuestionNode]: AddressQuestionNode,
  [DialogNodeTypes.MessageDialogNode]: MessageDialogNode,
  [DialogNodeTypes.TextQuestionNode]: TextQuestionNode,
  [DialogNodeTypes.RedirectDialogNode]: RedirectDialogNode,
  [DialogNodeTypes.NumberQuestionNode]: NumberQuestionNode,
  [DialogNodeTypes.DecisiveQuestionNode]: DecisiveQuestionNode,
  [DialogNodeTypes.EmailQuestionNode]: EmailQuestionNode,
  [DialogNodeTypes.NameQuestionNode]: NameQuestionNode,
  [DialogNodeTypes.ChoiceQuestionNode]: ChoiceQuestionNode,
  [DialogNodeTypes.DateTimeQuestionNode]: DateTimeQuestionNode,
  [DialogNodeTypes.PhoneQuestionNode]: PhoneQuestionNode,
  [DialogNodeTypes.AttachmentQuestionNode]: AttachmentQuestionNode,
  [DialogNodeTypes.InfoAdaptiveCardNode]: InfoAdaptiveCardNode,
  [DialogNodeTypes.QuestionAdaptiveCardNode]: QuestionAdaptiveCardNode,
  [DialogNodeTypes.ActionAdaptiveCardNode]: ActionAdaptiveCardNode,
  [DialogNodeTypes.EmailActionNode]: EmailActionNode,
  [DialogNodeTypes.ApiActionNode]: ApiActionNode,
  [DialogNodeTypes.KnowledgebaseNode]: KnowledgebaseNode,
  [DialogNodeTypes.ContextActionNode]: ContextActionNode,
  [DialogNodeTypes.DatasetActionNode]: DatasetActionNode,
  [DialogNodeTypes.StorageActionNode]: StorageActionNode,
  [DialogNodeTypes.EntityQuestionNode]: EntityQuestionNode,
  [DialogNodeTypes.ConfirmationActionNode]: ConfirmationActionNode,
};

export const BLOCK_CLASS_MAPPING = {
  [DialogBlockTypes.DialogBlock]: DialogBlock,
  [DialogBlockTypes.DefaultDialogBlock]: DefaultDialogBlock,
  [DialogBlockTypes.KnowledgebaseBlock]: KnowledgebaseBlock,
};
