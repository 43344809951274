import React, { useState } from 'react';
import { Text } from '../../../../models/Utilities/Text';
import TextBoxWithCtx from '../../../common/inputElements/TextBoxWithCtx';
import MessageParagraph from './MessageParagraph';

interface IProps {
  isActive: boolean;
  message: Text;
  clickHandler: () => void;
  removeHandler: () => void;
}
const MessageItem: React.FC<IProps> = ({
  isActive,
  clickHandler,
  removeHandler,
  message,
}) => {
  const [activeSsml, setActiveSsml] = useState(false);

  const handleMessageChange = (newContent: string) => {
    message!.value = newContent;
  };

  const handleSsmlChange = (newContent: string) => {
    message!.ssml = newContent;
  };

  return (
    <div className='message-item' id={message.id} onClick={clickHandler}>
      {isActive ? (
        <>
          {activeSsml ? (
            <TextBoxWithCtx
              autofocus
              value={message!.ssml ?? ''}
              saveHandler={handleSsmlChange}
              placeholder='Enter speech markup statement'
              multiline
            />
          ) : (
            <TextBoxWithCtx
              autofocus
              value={message!.value ?? ''}
              saveHandler={handleMessageChange}
              placeholder='Enter a message'
              multiline
            />
          )}
        </>
      ) : (
        <MessageParagraph
          activeSsml={activeSsml}
          setActiveSsml={setActiveSsml}
          message={message}
          placeholder={activeSsml ? 'Enter speech markup statement' : 'Enter a message'}
          removeHandler={removeHandler}
        />
      )}
      <hr />
    </div>
  );
};

export default React.memo(
  MessageItem,
  (prevProps, nextProps) => prevProps.isActive === nextProps.isActive
);
