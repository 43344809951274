import { action, computed, makeAutoObservable, observable } from 'mobx';
import { KnowledgebaseState } from '../architecture/enums/KnowledgebaseState';
import type {
  GetKnowledgebaseResponse,
  Source,
} from '../architecture/interfaces/HTTP/KnowledgebaseParams';
import { IAddedSource } from '../architecture/interfaces/knowledgebase/IAddedSource';
import KnowledgebaseConnector from '../models/Connectors/KnowledgebaseConnector';
import {
  ISerializedKnowledgebaseEntry,
  KnowledgebaseEntry,
} from '../models/Knowledgebase/KnowledgebaseEntry';
import { Question } from '../models/Knowledgebase/Question';
import { Notification } from '../models/Utilities/Notification';
import { Text } from '../models/Utilities/Text';
import { RootStore } from './rootStore';

class KnowledgebaseStore {
  private static instance: KnowledgebaseStore;

  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
    KnowledgebaseStore.instance = this;
  }

  @observable
  state: KnowledgebaseState = KnowledgebaseState.None;

  @observable
  private _knowledgebaseEntryRepository: KnowledgebaseEntry[] = [];

  @observable
  private _searchQuery?: string;

  @computed
  getState(state: KnowledgebaseState): boolean {
    return (this.state & state) === state;
  }

  @computed
  get isActive() {
    return !!this.rootStore.blockStore.getKnowledgebaseBlock();
  }

  @computed
  setState(state: KnowledgebaseState) {
    this.state |= state;
  }

  @computed
  removeState(state: KnowledgebaseState) {
    this.state &= ~state;
  }

  @observable
  sources: Source[] = [];

  @observable
  removedSources: Source[] = [];

  @observable
  addedSources: IAddedSource[] = [];

  @computed
  get allKnowledgebaseEntries() {
    if (!this.isActive) return [];

    // It's not possible to directly sort an observable, so a copy is used:
    // https://dev.to/acro5piano/to-pass-mobxs-observable-array-to-flatlist-slice-is-needed-2b45
    return this._knowledgebaseEntryRepository.slice().sort((a, b) => {
      return (b.id ?? Number.MAX_SAFE_INTEGER) - (a.id ?? Number.MAX_SAFE_INTEGER);
    });
  }

  @computed
  get activeKnowledgebaseEntries() {
    return this.allKnowledgebaseEntries.filter(
      (knowledgebaseEntry) => knowledgebaseEntry.state !== 'Deleted'
    );
  }

  @computed
  get isInErrorState() {
    return this._knowledgebaseEntryRepository.some(
      (entry) =>
        !entry.answer.value ||
        entry.questions.length === 0 ||
        entry.questions.every((q) => q.state === 'Deleted' || q.state === 'Initialized')
    );
  }

  @computed
  get sourcesAreModified() {
    return this.removedSources.length > 0 || this.addedSources.length > 0;
  }

  @computed
  get filteredKnowledgebaseEntries() {
    if (!this._searchQuery) {
      return this.allKnowledgebaseEntries;
    }

    return this.allKnowledgebaseEntries.filter(
      (entry) =>
        entry.answer.value.toLowerCase().includes(this._searchQuery!.toLowerCase()) ||
        entry.questions.find((q) =>
          q.text.value.toLowerCase().includes(this._searchQuery!.toLowerCase())
        )
    );
  }

  @action
  setSearchQuery(query?: string) {
    this._searchQuery = query;
  }

  @action
  isEdited() {
    return (
      this._knowledgebaseEntryRepository.some((entry) => entry.state !== 'UpToDate') ||
      this._knowledgebaseEntryRepository.some((entry) =>
        entry.questions.some((q) => q.state !== 'UpToDate')
      )
    );
  }

  @action
  async export(format: 'tsv' | 'excel'): Promise<ArrayBuffer | undefined> {
    return await KnowledgebaseConnector.export(
      this.currentlyEditedBotId,
      this.rootStore.dialogStore.currentlyEditedDialog?.dialogId,
      format
    );
  }

  @action
  discardChanges() {
    this.removedSources = [];
    this.addedSources = [];

    this._knowledgebaseEntryRepository = this._knowledgebaseEntryRepository.filter(
      (entry) => entry.state !== 'Added'
    );

    this._knowledgebaseEntryRepository.forEach((entry) => {
      entry.discardExistingEntry();
    });
  }

  @action
  addRow() {
    const newEntry = new KnowledgebaseEntry();
    newEntry.state = 'Added';

    this._knowledgebaseEntryRepository.unshift(newEntry);
  }

  @action
  markSourceForDeletion(source: Source) {
    this.sources = this.sources.filter((s) => s.displayName !== source.displayName);

    if (this.addedSources.find((s) => s.displayName === source.displayName)) {
      this.addedSources = this.addedSources.filter(
        (s) => s.displayName !== source.displayName
      );
    } else {
      this.removedSources.push(source);
    }
  }

  @action
  async removeSource(source: Source) {
    const dialogId = this.rootStore.dialogStore.currentlyEditedDialog?.dialogId;
    if (!dialogId) {
      return;
    }

    this.setState(KnowledgebaseState.SourcesLoading);
    const updatedKnowledgebase = await KnowledgebaseConnector.removeSource(
      this.currentlyEditedBotId,
      dialogId,
      source.displayName
    );

    this.enrichEntriesWithAttachments(updatedKnowledgebase);

    this.removeState(KnowledgebaseState.SourcesLoading);
  }

  @action
  bufferSourceForImport(formData: FormData, displayName?: string) {
    if (displayName) {
      this.sources.push({
        displayName: displayName,
        sourceKind: '',
        contentStructureKind: '',
        lastUpdatedDateTime: '',
      });

      this.addedSources.push({
        displayName,
        formData,
      });
    }
  }

  @action
  async importSource(formData: FormData) {
    const dialogId = this.rootStore.dialogStore.currentlyEditedDialog?.dialogId;
    if (!dialogId) {
      return;
    }

    this.setState(KnowledgebaseState.SourcesLoading);
    const updatedKnowledgebase = await KnowledgebaseConnector.importSource(
      this.currentlyEditedBotId,
      dialogId,
      formData
    );
    this.removeState(KnowledgebaseState.SourcesLoading);

    this.enrichEntriesWithAttachments(updatedKnowledgebase);
  }

  @action
  async loadSources() {
    try {
      this.setState(KnowledgebaseState.SourcesLoading);
      const dialog = this.rootStore.dialogStore.currentlyEditedDialog;
      if (dialog) {
        this.sources = await KnowledgebaseConnector.getSources(
          this.currentlyEditedBotId,
          dialog.dialogId
        );
      }
      this.removeState(KnowledgebaseState.SourcesLoading);
    } catch (error) {
      this.removeState(KnowledgebaseState.SourcesLoading);
    }
  }

  @action
  async update() {
    try {
      if (!this.isActive) return;

      await this.updateSources();

      if (!this.isEdited()) return;

      const dialog = this.rootStore.dialogStore.currentlyEditedDialog!;
      const updatedKnowledgebase = await KnowledgebaseConnector.update(
        this.currentlyEditedBotId,
        dialog.dialogId
      );

      this._knowledgebaseEntryRepository.map((entry) => {
        entry.initialAnswer.value = entry.answer.value;
        entry.questions.map((q) => {
          q.initialText = q.text.value;
          q.state = 'UpToDate';
        });
      });

      this.enrichEntriesWithAttachments(updatedKnowledgebase);
    } catch (error) {
      new Notification({ text: 'Saving Knowledge Base failed', type: 'error' });
      this.removeState(KnowledgebaseState.SourcesLoading);
    }
  }

  @action
  async updateSources() {
    if (!this.sourcesAreModified) {
      return;
    }

    if (this.removedSources.length > 0) {
      for (const source of this.removedSources) {
        await this.removeSource(source);
      }
      this.removedSources = [];
    }

    if (this.addedSources.length > 0) {
      for (const source of this.addedSources) {
        await this.importSource(source.formData);
      }
      this.addedSources = [];
    }

    await this.loadSources();
  }

  @action
  getById(id: number) {
    return this.activeKnowledgebaseEntries.find(
      (knowledgebaseEntry) => knowledgebaseEntry.id === id
    );
  }

  @action
  purge() {
    this._knowledgebaseEntryRepository = [];
  }

  @action
  remove(knowledgebaseEntry: KnowledgebaseEntry) {
    this._knowledgebaseEntryRepository = this._knowledgebaseEntryRepository.filter(
      (item) => item.id !== knowledgebaseEntry.id
    );
  }

  @action
  private enrichEntriesWithAttachments(knowledgebase: GetKnowledgebaseResponse) {
    if (!knowledgebase?.entries) return;

    const repositoryBackup = [...this._knowledgebaseEntryRepository];
    this.purge();

    knowledgebase.entries.forEach((entry) => {
      if (!entry.id) return;

      const backupItem = repositoryBackup.find(
        (backupEntry) => backupEntry.id === entry.id
      );
      if (!backupItem) {
        this._knowledgebaseEntryRepository.push(
          new KnowledgebaseEntry(
            entry.id,
            entry.answer,
            entry.questions,
            entry.lastUpdatedDateTime
          )
        );
      } else {
        // Update old entry
        backupItem.answer = new Text(entry.answer, backupItem.answer.ssml);
        backupItem.initialAnswer = new Text(entry.answer, backupItem.answer.ssml);

        backupItem.questions = entry.questions.map(
          (question: string) => new Question(new Text(question), 'UpToDate')
        );

        backupItem.state = 'UpToDate';
        backupItem.lastEdited = new Date(entry.lastUpdatedDateTime);

        this._knowledgebaseEntryRepository.push(backupItem);
      }
    });
  }

  @action
  public restore(entry: KnowledgebaseEntry) {
    if (!entry) return;

    this._knowledgebaseEntryRepository.push(entry);
  }

  @action
  public serialize(): ISerializedKnowledgebaseEntry[] {
    return this._knowledgebaseEntryRepository.map((entry) => entry.serialize());
  }

  private get currentlyEditedBotId() {
    return this.rootStore.dialogStore.currentlyEditedBot?.botId;
  }

  static getInstance() {
    if (!this.instance) {
      throw new Error('KnowledgebaseStore instance has not been initialized.');
    }

    return this.instance;
  }
}

export default KnowledgebaseStore;
