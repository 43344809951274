import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { useHistory } from 'react-router';
import rootStore from '../../stores/rootStore';
import { useGetIcon } from '../customHooks/useGetIcon';

const SettingsSelector: React.FC = () => {
  const getIcon = useGetIcon();
  const history = useHistory();

  const { dialogStore } = useContext(rootStore);

  const dialogId = dialogStore.currentlyEditedDialog?.dialogId;
  if (!dialogId) {
    return <></>;
  }

  const pathPrefix = `/dialogs/${dialogId}/bot-settings`;

  return (
    <div className='selector-page'>
      <div className='card-container'>
        <div className='card-group'>
          <div
            className='card'
            onClick={() => {
              history.push(`${pathPrefix}/bot`);
            }}
          >
            <div className='card-icon'>
              <i className={getIcon('robot')}></i>
            </div>
            <div className='card-text'>
              <span>Bot</span>
            </div>
          </div>
          <div
            className='card'
            onClick={() => {
              history.push(`${pathPrefix}/import-export`);
            }}
          >
            <div className='card-icon'>
              <i className={getIcon('exchange')}></i>
            </div>
            <div className='card-text'>
              <span>Import & Export</span>
            </div>
          </div>
          <div
            className='card'
            onClick={() => {
              history.push(`${pathPrefix}/confirmation`);
            }}
          >
            <div className='card-icon'>
              <i className={getIcon('check')}></i>
            </div>
            <div className='card-text'>
              <span>Confirm dataset view</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(SettingsSelector);
