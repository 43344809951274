import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { CommunicationDialogNode } from '../../../../models/DialogNodes/CommunicationDialogNode';
import { GroupedMultiQuestionNode } from '../../../../models/DialogNodes/QuestionNodes/MultiQuestionNode/GroupedMultiQuestionNode';
import { MultiQuestionNode } from '../../../../models/DialogNodes/QuestionNodes/MultiQuestionNode/MultiQuestionNode';
import { SingleQuestionNode } from '../../../../models/DialogNodes/QuestionNodes/SingleQuestionNode';
import Button from '../../../common/Button';
import Input from '../../../common/Input';
import Switch from '../../../common/inputElements/Switch';
import { useGetIcon } from '../../../customHooks/useGetIcon';
import Example from '../questionNodes/Entity/examples/Example';

interface IProps {
  dialogNode: CommunicationDialogNode;
}

const MAX_AUTO_RECOGNITION_MESSAGES = 5;

const AutoRecognition: React.FC<IProps> = ({ dialogNode }) => {
  const getIcon = useGetIcon();

  const [autoRecognitionLimit, setAutoRecognitionLimit] = useState(
    dialogNode.autoRecognitionLimit !== undefined ? dialogNode.autoRecognitionLimit : 1
  );

  const entities = (() => {
    if (dialogNode instanceof SingleQuestionNode) {
      const singleQuestionNode = dialogNode as SingleQuestionNode;
      if (singleQuestionNode.contextVariable) {
        return [singleQuestionNode.contextVariable.name];
      }
    } else if (dialogNode instanceof MultiQuestionNode) {
      const multiQuestionNode = dialogNode as MultiQuestionNode;
      if (
        multiQuestionNode.subQuestions.length !== 0 &&
        multiQuestionNode.subQuestions.some((subQuestion) => subQuestion.ctx)
      ) {
        return multiQuestionNode.subQuestions.map((subQuestion) => subQuestion.ctx.name);
      }
    } else if (dialogNode instanceof GroupedMultiQuestionNode) {
      const groupedMultiQuestionNode = dialogNode as GroupedMultiQuestionNode;
      if (groupedMultiQuestionNode.subQuestions.length !== 0) {
        return groupedMultiQuestionNode.subQuestions.map(
          (subQuestion) => `${groupedMultiQuestionNode.prefix}.${subQuestion.groupKey}`
        );
      }
    }

    return [];
  })();

  const autoRecognitionActive = dialogNode.autoRecognitionLimit !== undefined;

  const renderOption = (): JSX.Element | undefined => {
    return (
      <>
        <Switch
          key='autoRecognition'
          value={autoRecognitionActive}
          clickHandler={() => {
            if (autoRecognitionActive) {
              dialogNode.autoRecognitionLimit = undefined;
            } else {
              dialogNode.autoRecognitionLimit = autoRecognitionLimit;
            }
          }}
          label={
            <div className='auto-recognition-label'>
              <span>The previous </span>
              <Input
                key='autoRecognitionInput'
                inputType='number'
                value={autoRecognitionLimit.toString()}
                changeHandler={(value) => {
                  const numValue = Number(value);
                  dialogNode.autoRecognitionLimit = numValue;
                  setAutoRecognitionLimit(numValue);
                }}
                clickHandler={(e) => e.stopPropagation()}
                min={0}
                max={MAX_AUTO_RECOGNITION_MESSAGES}
              />
              <span>messages will be included in the analysis of this question</span>
            </div>
          }
        />
      </>
    );
  };

  const renderEntityDefinitions = () => {
    return dialogNode.autoRecognitionExamples?.map((entityDefinition, index) => {
      return (
        <Example
          entityDefinition={entityDefinition}
          removeEntityDefinition={(entityDefinition) => {
            dialogNode.removeAutoRecognitionExample(entityDefinition);
          }}
          key={index}
          entities={entities!}
          allowNewEntities={false}
        />
      );
    });
  };

  const addExample = () => {
    dialogNode.addAutoRecognitionExample();
  };

  const renderExampleArea = (): JSX.Element => {
    return (
      <div className='examples-area'>
        {renderEntityDefinitions()}
        <div
          className='add-example'
          style={{
            flexDirection: 'unset',
          }}
        >
          <Button
            content='Add example'
            className={'btn-primary btn-round'}
            icon={getIcon('add_alt')}
            clickHandler={addExample}
          />
        </div>
      </div>
    );
  };

  const renderExplanation = (): JSX.Element => {
    return <p>Please assign a context variable to this question first.</p>;
  };

  return (
    <div className='auto-recognition-container'>
      {entities.length === 0 && renderExplanation()}
      {entities.length > 0 && renderOption()}
      {autoRecognitionActive &&
        dialogNode.autoRecognitionExamplesAllowed &&
        entities.length > 0 &&
        renderExampleArea()}
    </div>
  );
};

export default observer(AutoRecognition);
