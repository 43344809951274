import { Exclude, Expose } from 'class-transformer';
import { action, computed, makeObservable, observable } from 'mobx';
import { FacialExpressions } from '../../architecture/enums/FacialExpressions';
import KnowledgebaseStore from '../../stores/KnowledgebaseStore';
import { ActionAttachment } from '../DialogNodes/ActionAttachment';
import { ISerializedMedia, Media } from '../Media/Media';
import {
  TransformIntoActions,
  TransformIntoMedia,
  TransformIntoQuestion,
  TransformIntoText,
} from '../Utilities/Deserialization/Decorators';
import { Notification } from '../Utilities/Notification';
import { ISerializedText, Text } from '../Utilities/Text';
import { Question } from './Question';
import type { State } from './State';

export class KnowledgebaseEntry {
  @Expose()
  id: number | undefined;

  @Expose()
  @TransformIntoText
  answer: Text;

  @Exclude()
  initialAnswer: Text;

  @Expose()
  @TransformIntoQuestion
  questions: Question[];

  @Exclude()
  state: State = 'UpToDate';

  @Expose()
  isSpeechMarkupDisplayed: boolean;

  @Expose()
  @TransformIntoMedia
  mediaFiles: Media[];

  @Expose()
  @TransformIntoActions
  actions: ActionAttachment[];

  @Expose()
  facialExpression: FacialExpressions | undefined;

  @Expose()
  lastEdited?: Date;

  constructor(id?: number, answer?: string, questions?: string[], lastEdited?: string) {
    this.id = id;
    this.answer = answer ? new Text(answer) : new Text('');
    this.initialAnswer = answer ? new Text(answer) : new Text('');
    this.questions = questions
      ? questions.map((question) => new Question(new Text(question)))
      : [];
    this.lastEdited = lastEdited !== undefined ? new Date(lastEdited) : undefined;

    this.mediaFiles = [];
    this.actions = [];
    this.facialExpression = undefined;
    this.isSpeechMarkupDisplayed = false;

    makeObservable(this, {
      id: observable,
      answer: observable,
      initialAnswer: observable,
      questions: observable,
      mediaFiles: observable,
      actions: observable,
      state: observable,
      facialExpression: observable,
      isSpeechMarkupDisplayed: observable,
      activeQuestions: computed,
      addQuestion: action,
      remove: action,
      removeQuestion: action,
    });
  }

  get activeQuestions() {
    return this.questions.filter((question) => question.state !== 'Deleted');
  }

  setSsml(ssml: string) {
    this.answer.ssml = ssml;
  }

  setAnswer(answer: string) {
    this.answer.value = answer;
    if (this.state !== 'Added' && answer !== this.initialAnswer.value) {
      this.state = 'Modified';
    } else if (answer === this.initialAnswer.value && answer !== '') {
      this.state = 'UpToDate';
    }
  }

  areAttachmentsValid() {
    return this.actions.every((action) => action.isValid);
  }

  setFacialExpression(exp: string) {
    this.facialExpression = exp as FacialExpressions;
  }

  get numberOfAttachments() {
    return this.actions.length + this.mediaFiles.length + (this.facialExpression ? 1 : 0);
  }

  get attachmentsExist() {
    return this.numberOfAttachments > 0;
  }

  addQuestion(questionText: string) {
    if (
      !this.questions.some(
        (question) => question.text.value.toLowerCase() === questionText.toLowerCase()
      )
    ) {
      const newQuestion = new Question(new Text(questionText), 'Added');
      this.questions.push(newQuestion);
    } else {
      new Notification({
        text: 'Question already exists',
        type: 'error',
      });
    }
  }

  removeQuestion(question: Question) {
    if (question.state === 'Added' || question.state === 'Initialized') {
      this.questions = this.questions.filter((q) => q.text.value !== question.text.value);
    }

    question.setDeletedState();
  }

  revert() {
    if (this.answer.value !== this.initialAnswer.value) {
      this.state = 'Modified';
    } else {
      this.state = 'UpToDate';
    }
  }

  discardExistingEntry() {
    this.state = 'UpToDate';
    this.answer = new Text(this.initialAnswer.value, this.initialAnswer.ssml);
    this.questions = this.questions.filter((q) => q.state !== 'Added');
    this.questions.map((q: Question) => q.reset());
  }

  get genericActions() {
    return this.actions.filter((action) => action.type.toLowerCase() !== 'urlredirect');
  }

  get urlRedirectActions() {
    return this.actions.filter((action) => action.type.toLowerCase() === 'urlredirect');
  }

  addAction(action: ActionAttachment) {
    this.actions.unshift(action);
  }

  addRedirectAction() {
    const data = '{ "caption": "", "url": "" }';
    this.actions.unshift(new ActionAttachment('urlRedirect', data));
  }

  removeAction(action: ActionAttachment) {
    this.actions = this.actions.filter((item) => item !== action);
  }

  addMediaFiles(files: Media[]) {
    this.mediaFiles = files;
  }

  remove() {
    if (this.state === 'Added' || this.state === 'Initialized') {
      KnowledgebaseStore.getInstance().remove(this);
    } else {
      this.state = 'Deleted';
    }
  }

  serialize(): ISerializedKnowledgebaseEntry {
    const serializedMediaFiles = this.mediaFiles.map((file) => file.serialize());
    const stringifiedAction = JSON.stringify(
      this.actions.map((action) => action.serialize())
    );

    return {
      id: this.id,
      answer: this.answer.serialize(),
      questions: this.questions.map((question) => question.text.value),
      mediaFiles: serializedMediaFiles.length !== 0 ? serializedMediaFiles : undefined,
      actions: this.actions.length !== 0 ? stringifiedAction : undefined,
      facialExpression: this.facialExpression,
      lastEdited: this.lastEdited,
    };
  }
}

export interface ISerializedKnowledgebaseEntry {
  id?: number;
  answer: ISerializedText;
  questions: string[];
  mediaFiles?: ISerializedMedia[];
  actions?: string;
  facialExpression?: FacialExpressions;
  lastEdited?: Date;
}
