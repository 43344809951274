import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { DialogBlock as DialogBlockModel } from '../../../models/DialogBlocks/DialogBlock';
import { AiTrigger as AiTriggerModel } from '../../../models/Triggers/AiTrigger';
import Button from '../../common/Button';
import TextInputWithDelete from '../../common/inputElements/TextInputWithDelete';
import { useGetIcon } from '../../customHooks/useGetIcon';
import { useReadOnly } from '../../customHooks/useReadOnly';

interface IProps {
  block: DialogBlockModel;
}

const AiTrigger: React.FC<IProps> = ({ block }) => {
  const trigger = block.trigger as AiTriggerModel;

  const { getReadOnly } = useReadOnly();
  const readOnly = getReadOnly();
  const getIcon = useGetIcon();

  useEffect(() => {
    return () => {
      if (!trigger.isValid) {
        block.removeTrigger();
      }
    };
  }, []);

  const deleteExampleTopic = (id: string) => {
    trigger.deleteExampleTopic(id);
  };

  return (
    <>
      <TextInputWithDelete
        disabled={readOnly}
        key={trigger.intentDescription.id}
        textObject={trigger.intentDescription}
        deleteHandler={() => (trigger.intentDescription.value = '')}
        autoFocus
      />

      <div className='example-topics-description'>
        In some cases it might be difficult to find a single umbrella term for a trigger.
        During operation, it is also possible for individual user requirements to be
        misinterpreted. In these cases it can be benificial to add{' '}
        <span className='bold'>Example Topics </span>
        to the following list, which will help the AI to better understand the intention
        of this trigger.
      </div>

      <div className='example-topics'>
        {trigger.exampleTopics.map((topic) => {
          return (
            <TextInputWithDelete
              disabled={readOnly}
              key={topic.id}
              textObject={topic}
              deleteHandler={() => deleteExampleTopic(topic.id)}
              autoFocus
            />
          );
        })}
      </div>
      <Button
        icon={getIcon('add')}
        className={'btn btn-borderless'}
        content={'Add Example Topic'}
        clickHandler={() => trigger.addExampleTopic()}
      />
    </>
  );
};

export default observer(AiTrigger);
