import { action, computed, makeAutoObservable, observable } from 'mobx';
import { PanZoom } from 'panzoom';
import { DialogNodeTypes } from '../architecture/enums/DialogComponentType';
import { ModalElementTypes, ModalTypes } from '../architecture/enums/ModalTypes';
import variables from '../index.scss';
import { languageBundle, languages } from '../languages/languageBundle';
import { Notification } from '../models/Utilities/Notification';
import { RootStore } from './rootStore';

type IconMapperDefinition = {
  [k in DialogNodeTypes]: string;
};

interface ModalState {
  activeModalType?: ModalTypes;
  element?: ModalElementTypes;
  closeInterceptor?: () => void;
  preventClosing: boolean;
  tooltip?: string;
}

interface IPanZoomHandler {
  storedValues: {
    transformX: number;
    transformY: number;
    scale: number;
  };
  panZoomInstance: PanZoom | null;
}

export default class UIStore {
  private static instance: UIStore;

  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
    UIStore.instance = this;
  }

  @observable
  currentLanguage: keyof typeof languages = 'de-DE';

  @observable
  secondModalOpened: boolean = false;

  @computed
  get translation() {
    return languageBundle[this.currentLanguage];
  }

  colors = {
    primary: variables.primaryColor,
    secondary: variables.secondaryColor,
    text: variables.textColor,
    info: variables.infoColor,
    success: variables.successColor,
    error: variables.errorColor,
    darkRed: variables.darkRed,
    nodeError: variables.nodeErrorColor,
  };

  icons = {
    add: 'fas fa-plus',
    add_alt: 'fas fa-plus-circle',
    address: 'far fa-building',
    api: 'fas fa-external-link-alt',
    asterisk: 'fas fa-asterisk',
    back: 'fas fa-angle-left',
    ban: 'fas fa-ban',
    brain: 'fas fa-brain',
    body: 'fas fa-align-justify',
    calendar: 'far fa-calendar-alt',
    card: 'far fa-address-card',
    caret_down: 'fas fa-caret-down',
    caret_right: 'fas fa-caret-right',
    check: 'far fa-check-circle',
    check_simple: 'fas fa-check',
    check_double: 'fas fa-check-double',
    check_full: 'fas fa-check-circle',
    check_solid: 'fas fa-solid fa-check',
    circle: 'far fa-circle',
    clock: 'fas fa-clock',
    close: 'fas fa-times',
    close_circle: 'fas fa-times-circle',
    code: 'fas fa-code',
    coins: 'fas fa-coins',
    comment: 'fas fa-comment',
    comments: 'fas fa-comments',
    config: 'fas fa-cog',
    cogs: 'fas fa-cogs',
    conversation_history: 'far fa-comment',
    copy: 'fas fa-copy',
    csv: 'fas fa-file-csv',
    dashboard: 'fas fa-columns',
    database: 'fas fa-database',
    decisive: 'fas fa-compress-alt',
    default_trigger: 'fas fa-question',
    euro: 'fas fa-euro-sign',
    file: 'fas fa-file',
    doc: 'fas fa-file-alt',
    document: 'fas fa-solid fa-align-left',
    double_left: 'fas fa-solid fa-angle-double-left',
    double_right: 'fas fa-solid fa-angle-double-right',
    down: 'fas fa-angle-down',
    download: 'fas fa-download',
    dumpster: 'fas fa-dumpster',
    edit: 'fas fa-pen',
    edit_alt: 'far fa-edit',
    edit_bold: 'fas fa-edit',
    email: 'fas fa-at',
    envelope: 'far fa-envelope',
    envelope_full: 'fas fa-envelope',
    error: 'fas fa-exclamation-triangle',
    event_trigger: 'fas fa-solid fa-bolt',
    exchange: 'fas fa-exchange-alt',
    exclamation: 'fas fa-solid fa-exclamation',
    eye: 'fas fa-eye',
    eye_closed: 'fas fa-eye-slash',
    fast_forward: 'fas fa-fast-forward',
    file_exclamantion: 'fas fa-file-exclamation',
    filter: 'fas fa-regular fa-filter',
    font: 'fas fa-font',
    forward: 'fas fa-angle-right',
    hashtag: 'fas fa-hashtag',
    history: 'fas fa-history',
    home: 'fas fa-home',
    icons: 'fas fa-icons',
    image: 'fas fa-solid fa-image',
    images: 'fas fa-solid fa-images',
    import: 'fas fa-file-import',
    info: 'fas fa-info-circle',
    intent_trigger: 'fas fa-solid fa-comment',
    key: 'fas fa-key',
    layer: 'fas fa-layer-group',
    layout: 'fas fa-layer-group',
    left: 'fas fa-solid fa-arrow-left',
    link: 'fas fa-link',
    link_session: 'fas fa-solid fa-arrow-trend-up',
    list: 'far fa-list-alt',
    list_trigger: 'fas fa-solid fa-list-ul',
    lock: 'fas fa-lock',
    loading: 'fas fa-spinner',
    marker: 'fas fa-marker',
    message: 'far fa-comment',
    minus: 'fas fa-minus',
    minus_circle: 'fas fa-minus-circle',
    multichoice: 'fas fa-list',
    name: 'far fa-address-card',
    network: 'fas fa-network-wired',
    number: 'fas fa-superscript',
    more: 'fas fa-ellipsis-h',
    open: 'far fa-folder-open',
    paperclip: 'fas fa-paperclip',
    palette: 'fas fa-palette',
    pause: 'fas fa-pause',
    pdf: 'fas fa-solid fa-file-pdf',
    play: 'fas fa-solid fa-play',
    phone: 'fas fa-phone',
    presentation: 'fas fa-file-powerpoint',
    question: 'far fa-question-circle',
    receipt: 'fas fa-receipt',
    redirect: 'fas fa-directions',
    refresh: 'fas fa-redo-alt',
    regex_trigger: 'fas fa-solid fa-asterisk',
    remove: 'fas fa-trash',
    reply: 'fas fa-reply',
    right: 'fas fa-solid fa-arrow-right',
    right_circle: 'fas fa-arrow-circle-right',
    robot: 'fas fa-robot',
    rotate: 'fas fa-sync-alt',
    rotate_arrows: 'fas fa-rotate',
    save: 'far fa-save',
    sign_in: 'fas fa-sign-in-alt',
    sign_out: 'fas fa-sign-out-alt',
    search: 'fas fa-search',
    send: 'fas fa-paper-plane',
    smile: 'far fa-smile',
    sort_up: 'fas fa-sort-up',
    sort_up_down: 'fas fa-sort',
    sort_down: 'fas fa-sort-down',
    spreadsheet: 'fas fa-file-excel',
    star: 'fas fa-star',
    sound: 'fas fa-volume-up',
    table: 'fas fa-light fa-table',
    text: 'fas fa-quote-right',
    undo: 'fas fa-undo-alt',
    unlink: 'fas fa-unlink',
    up: 'fas fa-angle-up',
    upload: 'fas fa-upload',
    user: 'fas fa-user',
    users: 'fas fa-users',
    user_circle: 'fas fa-user-circle',
  };

  nodeIcons: IconMapperDefinition = {
    MessageDialogNode: this.icons['message'],
    AddressQuestionNode: this.icons['address'],
    NumberQuestionNode: this.icons['number'],
    TextQuestionNode: this.icons['text'],
    DecisiveQuestionNode: this.icons['decisive'],
    ChoiceQuestionNode: this.icons['list'],
    EmailQuestionNode: this.icons['email'],
    NameQuestionNode: this.icons['name'],
    RedirectDialogNode: this.icons['redirect'],
    EmailActionNode: this.icons['envelope'],
    ApiActionNode: this.icons['robot'],
    ContextActionNode: this.icons['config'],
    DatasetActionNode: this.icons['table'],
    StorageActionNode: this.icons['database'],
    PhoneQuestionNode: this.icons['phone'],
    InfoAdaptiveCardNode: this.icons['card'],
    QuestionAdaptiveCardNode: this.icons['card'],
    ActionAdaptiveCardNode: this.icons['card'],
    DateTimeQuestionNode: this.icons['calendar'],
    EntityQuestionNode: this.icons['icons'],
    ConfirmationActionNode: this.icons['check_double'],
    AttachmentQuestionNode: this.icons['paperclip'],
    RootNode: '',
    KnowledgebaseNode: '',
    Undefined: '',
  };

  // Instance of the PanZoom that gets registered in the DialogBlockList React component's useEffect hook.
  // The instance is stored in the UIStore because it will be paused and resumed in other components,
  // such as the DialogBlock Title Textarea or the Draggable Block itself to avoid collision between dragging a block and dragging the DialogBlockList on the Dashboard

  @observable
  dialogBlockPanZoomHandler: IPanZoomHandler = {
    storedValues: {
      transformX: 0,
      transformY: 0,
      scale: 1,
    },
    panZoomInstance: null,
  };

  /**
   * Resets the PanZoom instances transformation values (x, y, scale)
   */
  @action
  resetTransformations() {
    this.dialogBlockPanZoomHandler.storedValues = {
      transformX: 0,
      transformY: 0,
      scale: 1,
    };
    this.dialogBlockPanZoomHandler.panZoomInstance?.moveTo(0, 0);
    this.dialogBlockPanZoomHandler.panZoomInstance?.zoomAbs(1, 1, 1);
  }

  @observable
  modalState: ModalState = {
    activeModalType: undefined,
    element: undefined,
    closeInterceptor: undefined,
    preventClosing: false,
    tooltip: undefined,
  };

  @observable
  notifications: Notification[] = [];

  // The container element that displays the list of dialog nodes within a block.
  // Will be used to attach a scrollTo event to the currently active node list container
  @observable
  nodeListContainer: HTMLDivElement | null = null;

  // Target can be any button that has the "name" prop to display an inline loading indicator while an async request is being processed
  @observable
  loading = {
    targetName: '',
    isLoading: false,
  };

  @action
  resetLoading() {
    this.loading = {
      targetName: '',
      isLoading: false,
    };
  }

  @action
  openSecondModal() {
    this.secondModalOpened = true;
  }

  @action
  closeSecondModal() {
    this.secondModalOpened = false;
  }

  @computed
  get showModal() {
    return !!this.modalState.activeModalType;
  }

  @action
  scrollToTop(element: HTMLElement) {
    element.scrollTo({ behavior: 'smooth', top: 0 });
  }

  @action
  scrollToElement(element: HTMLElement, destination: 'bottom' | 'top' = 'bottom') {
    switch (destination) {
      case 'bottom':
        setTimeout(
          () => element.scrollTo({ behavior: 'smooth', top: element.scrollHeight }),
          30
        );
        break;
      default:
        this.scrollToTop(element);
        break;
    }
  }

  static getInstance() {
    if (!this.instance) {
      throw new Error('UIStore instance has not been initialized.');
    }

    return this.instance;
  }
}
