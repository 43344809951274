import { Exclude, Expose, Type } from 'class-transformer';
import { action, computed, makeObservable, observable } from 'mobx';
import { DialogBlockTypes } from '../../architecture/enums/DialogComponentType';
import { TriggerTypes } from '../../architecture/enums/TriggerTypes';
import type { IBlockGeometry } from '../../architecture/interfaces/BlockGeometry';
import BlockStore from '../../stores/BlockStore';
import CanvasStore from '../../stores/CanvasStore';
import NodeStore from '../../stores/NodeStore';
import UIStore from '../../stores/UIStore';
import { BaseDialogComponent } from '../BaseDialogComponent';
import { BaseDialogNode } from '../DialogNodes/BaseDialogNode';
import { RedirectableNode } from '../DialogNodes/RedirectableNode';
import { Redirect } from '../Redirects/Redirect';
import { AiTrigger } from '../Triggers/AiTrigger';
import { ContextTrigger } from '../Triggers/ContextTrigger';
import { EventTrigger } from '../Triggers/EventTrigger/EventTrigger';
import { IntentTrigger } from '../Triggers/IntentTrigger';
import { NodeTrigger } from '../Triggers/NodeTrigger';
import { RegexInputTrigger } from '../Triggers/RegexInputTrigger';
import { ISerializedTrigger, Trigger } from '../Triggers/Trigger';
import { UserInputTrigger } from '../Triggers/UserInputTrigger';
import { Position } from '../Utilities/Position';

const TRIGGER_DISCRIMINATOR = {
  discriminator: {
    property: 'type',
    subTypes: [
      { value: RegexInputTrigger, name: TriggerTypes.RegexInput },
      { value: UserInputTrigger, name: TriggerTypes.UserInput },
      { value: ContextTrigger, name: TriggerTypes.Context },
      { value: EventTrigger, name: TriggerTypes.Event },
      { value: IntentTrigger, name: TriggerTypes.Intent },
      { value: AiTrigger, name: TriggerTypes.AI },
      { value: NodeTrigger, name: TriggerTypes.Node },
    ],
  },
};

export class DialogBlock extends BaseDialogComponent {
  title: string;

  @Expose()
  position: Position;

  @Exclude()
  type: DialogBlockTypes = DialogBlockTypes.DialogBlock;

  @Expose()
  @Type(() => Trigger, TRIGGER_DISCRIMINATOR)
  trigger?: Trigger;

  @Exclude()
  geometry: IBlockGeometry = {
    height: 138.5,
    width: 300,
  };

  @Exclude()
  userModificationAllowed = true;

  constructor() {
    super();

    this.title = `Dialog Block ${BlockStore.getInstance().allBlocks.length}`;

    makeObservable(this, {
      isAttached: observable,
      isEmpty: observable,
      geometry: observable,
      type: observable,
      position: observable,
      trigger: observable,
      nodes: computed,
      rootNode: computed,
      isValid: computed,
      isTriggerNeeded: computed,
      hasSiblings: computed,
      isTriggerMissing: computed,
      isTriggerNeededButMissing: computed,
      addDialogNode: action,
      isAnotherRedirectNodeInBlockTargetingSameBlockAs: action,
      removeNodes: action,
    });

    this.position = CanvasStore.getInstance().getNewDialogBlockInsertionPosition();
  }

  get nodes() {
    return NodeStore.getInstance().allNodes.filter((node) => node.dialogBlock === this);
  }

  get rootNode() {
    return this.nodes.find((node) => node.isBlockRoot)!;
  }

  get topConnectorPosition() {
    return this.getTopConnectorPosition();
  }

  get isTriggerNeeded() {
    return this.isRoot;
  }

  /** Returns true if the parent block has more children than this current block. */
  get hasSiblings() {
    if (this.isRoot) {
      return (
        BlockStore.getInstance().allBlocks.filter((block) => block.isRoot).length > 0
      );
    }

    const siblingBlocks = this.parents
      .map((parent) => parent.getChildren())
      .flat()
      .filter((child) => child !== this);
    return siblingBlocks.length > 0;
  }

  /** Checks if the block has zero or more than 1 parents. In this case a trigger is needed
   * If the first part evaluates true the trigger will be evaluated: is it set and is is valid?
   */
  get isTriggerMissing() {
    return this.trigger === undefined || !this.trigger.isValid;
  }

  get isTriggerNeededButMissing() {
    return this.isTriggerNeeded && this.isTriggerMissing;
  }

  get isValid() {
    if (!this.trigger) return true;

    return this.trigger.isValid;
  }

  /**
   * Filters out the redirect nodes that belong to this block.
   * Then looks for a redirect node that is targeting the same block as the parameter node.
   * Finally it returns a boolean according to having found at least 1 such node.
   * @param redirectNode A redirect node instance
   * @param redirectToCompare A redirect instance that is being used to be more precise at determining which block connections should be removed while removing a redirect node completely.
   * @returns True if there is another redirect node that is targeting the same block as the parameter redirect node
   */
  isAnotherRedirectNodeInBlockTargetingSameBlockAs(
    redirectNode: RedirectableNode,
    redirectToCompare?: Redirect
  ) {
    // Filter out all redirect nodes in the block that are not equal to the provided parameter redirectNode
    const anotherRedirectNodeTargetingTheSameBlock = this.nodes
      .filter((node) => node.isRedirectable && node.id !== redirectNode.id)
      // Find the one node that:
      //  - has at least one redirect instance that is targeting the same block
      //  - has a redirect instance that matches the provided redirectToCompare instance
      // This makes it possible to not only check if the Node #1 and Node #2 have a redirect that is referencing Block #0 but to also check
      // if Node #1 has a redirect instance that is referencing a block that no other node is pointing at.
      // This is necessary to avoid the following behaviour:

      // If Node #1 has a redirect that is pointing to Block #0 and another one pointing to Block #1
      // and Node #2 has a redirect pointing to Block #0 and another one to Block #2
      // and we remove Node #1 we expect the Block #1 reference to be removed because no other redirect is pointing to Block #1 in this block.
      // BUT! Since they have a common target, this method would always return true because it will only be checked on NODE LEVEL if two nodes have a common target.
      // Therefore the Block #1 connection wouldn't be erased either. Comparing the redirect instances to the redirectToCompare parameter solves this problem.
      .find((node) => {
        return (node as RedirectableNode).redirects.find((redirect) =>
          redirectNode.redirects.find(
            (red) => red === redirectToCompare && red.targetBlock === redirect.targetBlock
          )
        );
      });

    return anotherRedirectNodeTargetingTheSameBlock !== undefined;
  }

  addParent(parentBlock: DialogBlock) {
    super.addParent(parentBlock);
    CanvasStore.getInstance().updateBlockConnection(parentBlock, this);
  }

  addChild(childBlock: DialogBlock) {
    super.addChild(childBlock);
    CanvasStore.getInstance().updateBlockConnection(this, childBlock);
  }

  /**
   * Adds a new dialog node to to this dialog block. If parentNode is specified then
   * this node will be added as child of parentNode, else it will be added as block leaf.
   *
   * @param dialogNode - The dialog node to add
   * @param parentNode - Optional. If specified the new dialog node will be added as child of parent node
   */
  addDialogNode(
    dialogNode: BaseDialogNode,
    parentNode: BaseDialogNode | undefined = undefined
  ) {
    if (parentNode && !(parentNode.dialogBlock === this)) {
      throw new Error('parentNode is not part of the current block.');
    }
    dialogNode.dialogBlock = this;
    if (parentNode) {
      parentNode.setBlockChild(dialogNode);
    } else if (this.isEmpty()) {
      // If the new dialog node is the first node in this block, make sure to update all
      // leaf nodes of all those blocks which reference this block
      this.updateAllLeafDialogNodesFromAllParentDialogBlocks();
    } else {
      this.leafDialogNode!.setBlockChild(dialogNode);
    }
  }

  getChild(childId: string) {
    return super.getChild(childId);
  }

  get leafDialogNode() {
    if (this.isEmpty()) return undefined;

    return NodeStore.getInstance().allNodes.find(
      (x) => x.dialogBlock === this && x.isBlockLeaf()
    );
  }

  get rootDialogNode() {
    if (this.isEmpty()) return undefined;

    return NodeStore.getInstance().allNodes.find(
      (x) => x.dialogBlock === this && x.isBlockRoot
    ) as BaseDialogNode;
  }

  get bottomConnectorPosition() {
    return this.getBottomConnectorPosition();
  }

  getChildren(): DialogBlock[] {
    return super.getChildren() as DialogBlock[];
  }

  getParents(): DialogBlock[] {
    return super.getParents() as DialogBlock[];
  }

  isAttached() {
    return document.getElementById(this.id) !== null;
  }

  isEmpty() {
    return this.nodes.length === 0;
  }

  serialize(): ISerializedBlock {
    return {
      ...super.serialize(),
      parents: this.getParents().map((parent: DialogBlock) => parent.id),
      children: this.getChildren().map((child: DialogBlock) => child.id),
      position: this.position,
      trigger: this.trigger?.serialize(),
      rootNodeId: this.rootNode.id,
      type: this.type,
    };
  }

  remove() {
    this.removeNodes();
    this.removeTrigger();
    super.remove();
  }

  removeNodes() {
    this.nodes.forEach((node) => NodeStore.getInstance().remove(node));
  }

  removeTrigger() {
    if (this.trigger?.type === TriggerTypes.Intent) {
      (this.trigger as IntentTrigger).remove();
    }

    this.trigger = undefined;
  }

  removeChild(childBlock: DialogBlock) {
    super.removeChild(childBlock);
    CanvasStore.getInstance().removeBlockConnection(this, childBlock);
  }

  removeParent(parentBlock: DialogBlock) {
    super.removeParent(parentBlock);
    CanvasStore.getInstance().removeBlockConnection(parentBlock, this);
  }

  private getBottomConnectorPosition(): Position {
    const elem = document.querySelector(`[data-block-id="${this.id}"]`);

    // There are occasions where the connector is being calculated but the blocks are not mounted in the DOM.
    // This return is used as a fallback to avoid exceptions in this case.
    // After the Canvas (and general design) rework there will be a better way and better place to handle block connection arrows.
    if (!elem) {
      return new Position(
        this.position.x + this.geometry.width / 2,
        this.position.y + this.geometry.height
      );
    }

    const coordinates = elem.getBoundingClientRect();
    const scale =
      UIStore.getInstance().dialogBlockPanZoomHandler.panZoomInstance?.getTransform()
        .scale ?? 1;
    const bottomCircleOffset = 20;

    return new Position(
      coordinates.left + coordinates.width / 2,
      coordinates.bottom - this.geometry.height / 2 + bottomCircleOffset * scale
    );
  }

  private getTopConnectorPosition(): Position {
    const elem = document.querySelector(`[data-block-id="${this.id}"]`) as HTMLDivElement;

    // There are occasions where the connector is being calculated but the blocks are not mounted in the DOM.
    // This return is used as a fallback to avoid exceptions in this case.
    // After the Canvas (and general design) rework there will be a better way and better place to handle block connection arrows.
    if (!elem)
      return new Position(this.position.x + this.geometry.width / 2, this.position.y);

    const coordinates = elem.getBoundingClientRect();

    return new Position(
      coordinates.left + coordinates.width / 2,
      coordinates.top - this.geometry.height / 2
    );
  }

  private updateAllLeafDialogNodesFromAllParentDialogBlocks() {
    for (let blockParent of this.getParents()) {
      blockParent.leafDialogNode!.setBlockChild(this.rootDialogNode);
    }
  }
}

export interface ISerializedBlock {
  id: string;
  title?: string;
  parents: string[];
  children: string[];
  type: DialogBlockTypes;
  position: Position;
  trigger?: ISerializedTrigger;
  rootNodeId: string;
}
