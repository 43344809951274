import { observer } from 'mobx-react-lite';
import React from 'react';
import { useHistory } from 'react-router';
import { useGetIcon } from '../../customHooks/useGetIcon';

interface IProps {}

const SettingsSelector: React.FC<IProps> = ({}) => {
  const getIcon = useGetIcon();
  const history = useHistory();

  return (
    <div className='selector-page'>
      <div className='card-container'>
        <div className='card-group'>
          <div
            className='card'
            onClick={() => {
              history.push('/settings/subscriptions');
            }}
          >
            <div className='card-icon'>
              <i className={getIcon('exchange')}></i>
            </div>
            <div className='card-text'>
              <span>Switch Subscription</span>
            </div>
          </div>
          <div
            className='card'
            onClick={() => {
              history.push('/settings/profile');
            }}
          >
            <div className='card-icon'>
              <i className={getIcon('user')}></i>
            </div>
            <div className='card-text'>
              <span>Update Profile</span>
            </div>
          </div>
          <div
            className='card'
            onClick={() => {
              history.push('/settings/password');
            }}
          >
            <div className='card-icon'>
              <i className={getIcon('lock')}></i>
            </div>
            <div className='card-text'>
              <span>Change Password</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(SettingsSelector);
