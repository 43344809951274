import { observer } from 'mobx-react-lite';
import React, { useContext, useState } from 'react';
import { ModalTypes } from '../../../architecture/enums/ModalTypes';
import rootStore from '../../../stores/rootStore';
import Button from '../../common/Button';
import Modal from '../../common/modal/Modal';
import { useGetIcon } from '../../customHooks/useGetIcon';
import useModal from '../../customHooks/useModal';
import { useReadOnly } from '../../customHooks/useReadOnly';
import ImportExportFiles from './ImportExportFiles';

const Actions: React.FC = () => {
  const [openImportExportModal, setOpenImportExportModal] = useState(false);
  const getIcon = useGetIcon();
  const { showModal } = useModal();
  const { knowledgebaseReadOnlyMode } = useReadOnly();
  const { knowledgebaseStore } = useContext(rootStore);

  return (
    <div className='actions-wrapper'>
      <Button
        content='Import / Export'
        disabled={knowledgebaseStore.isEdited() || knowledgebaseReadOnlyMode}
        className='btn-round btn-inactive btn-action'
        icon={getIcon('import')}
        reverseContent={true}
        clickHandler={() => setOpenImportExportModal(true)}
      ></Button>
      <Button
        content='Discard changes'
        disabled={
          knowledgebaseReadOnlyMode ||
          (!knowledgebaseStore.isEdited() && !knowledgebaseStore.sourcesAreModified)
        }
        className='btn-round btn-inactive btn-action'
        icon={getIcon('history')}
        reverseContent={true}
        clickHandler={() => showModal(ModalTypes.DiscardKnowledgebaseChanges)}
      ></Button>
      <Button
        content='Add a new row'
        disabled={knowledgebaseReadOnlyMode}
        className='btn-round btn-primary btn-action'
        icon={getIcon('add_alt')}
        reverseContent={true}
        clickHandler={() => knowledgebaseStore.addRow()}
      ></Button>
      <Modal
        manuallyTriggered={openImportExportModal}
        closeHandler={() => setOpenImportExportModal(false)}
      >
        <ImportExportFiles resetModal={() => setOpenImportExportModal(false)} />
      </Modal>
    </div>
  );
};

export default observer(Actions);
