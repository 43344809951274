import { action, makeObservable, observable } from 'mobx';
import { Dialog } from './Dialog';

export class Bot {
  constructor(
    public botId: string,
    public name: string,
    public creationDate: Date,
    public dialogs: Dialog[],
    public language: string,
    public usesKnowledgebase: boolean
  ) {
    makeObservable(this, {
      name: observable,
      dialogs: observable,
      usesKnowledgebase: observable,
      addDialog: action,
      removeDialog: action,
    });
  }

  addDialog(dialog: Dialog) {
    this.dialogs.push(dialog);
  }

  removeDialog(dialog: Dialog) {
    this.dialogs = this.dialogs.filter((item) => item !== dialog);
  }
}
