import { observable, makeObservable } from 'mobx';
import { TriggerHistoryElement } from './TriggerHistoryElement';

export class KnowledgebaseTriggerElement extends TriggerHistoryElement {
  score?: number;
  threshold?: number
  answer: string;

  constructor() {
    super();
    this.score = 0;
    this.threshold = 0
    this.answer = '';

    makeObservable(this, {
      score: observable,
      threshold: observable,
      answer: observable,
    });
  }
}
