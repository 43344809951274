import { observer } from 'mobx-react-lite';
import React from 'react';
import { ActionAttachment } from '../../../../models/DialogNodes/ActionAttachment';
import { CommunicationDialogNode } from '../../../../models/DialogNodes/CommunicationDialogNode';
import { InfoAdaptiveCardNode } from '../../../../models/DialogNodes/MessageNodes/InfoAdaptiveCardNode';
import { MessageDialogNode } from '../../../../models/DialogNodes/MessageNodes/MessageDialogNode';
import { QuestionDialogNode } from '../../../../models/DialogNodes/QuestionNodes/QuestionDialogNode';
import { AddressQuestionNode } from '../../../../models/DialogNodes/QuestionNodes/QuestionNodeTypes/AddressQuestionNode';
import Switch from '../../../common/inputElements/Switch';

interface IProps {
  dialogNode: CommunicationDialogNode;
}

const Settings: React.FC<IProps> = ({ dialogNode }) => {
  const handleActivateMicrophone = () => {
    if (dialogNode.activateMicrophone) {
      dialogNode.removeAction(dialogNode.activateMicrophone!);
    } else {
      dialogNode.addAction(new ActionAttachment('activateMicrophone'));
    }
  };

  const handleForceTextInputChange = () => {
    const castedNode = dialogNode as QuestionDialogNode;
    if (castedNode.forceTextInput) {
      castedNode.removeAction(castedNode.forceTextInput!);
    } else {
      castedNode.addAction(new ActionAttachment('forceTextInput'));
    }
  };

  //#region Create Settings Helper Functions Area

  const _createDefaultQuestionNodeSettings = (node: QuestionDialogNode) => [
    <Switch
      key='skipIfKnown'
      value={node.skipIfKnown}
      clickHandler={() => (node.skipIfKnown = !node.skipIfKnown)}
      label={'Skip question if context variable is set'}
    />,
    <Switch
      key='forceTextInput'
      value={!!node.forceTextInput}
      clickHandler={handleForceTextInputChange}
      label={'Force text input'}
    />,
    <Switch
      key='activateMicrophone'
      value={!!node.activateMicrophone}
      clickHandler={handleActivateMicrophone}
      label={'Automatically activate microphone after asking the question'}
      disabled={!!node.forceTextInput}
    />,
  ];

  const _createAddressNodeSettings = (node: AddressQuestionNode) => (
    <Switch
      key='verifyAddress'
      value={node.verifyAddress}
      clickHandler={() => (node.verifyAddress = !node.verifyAddress)}
      label='Verify Address'
    />
  );

  const _createDefaultMessageNodeSettings = () => {
    return (
      <Switch
        key='activateMicrophone'
        value={!!dialogNode.activateMicrophone}
        clickHandler={handleActivateMicrophone}
        label={
          'Should the microphone automatically be activated once the Avatar finishes talking?'
        }
      />
    );
  };
  //#endregion

  const createSettings = () => {
    if (
      dialogNode instanceof MessageDialogNode ||
      dialogNode instanceof InfoAdaptiveCardNode
    ) {
      return _createDefaultMessageNodeSettings();
    }

    // Dialog Node is a Question Node after this point
    let settings = _createDefaultQuestionNodeSettings(dialogNode as QuestionDialogNode);

    // Specifying additional settings for specific question nodes if necessary
    if (dialogNode instanceof AddressQuestionNode) {
      settings = [
        _createAddressNodeSettings(dialogNode as AddressQuestionNode),
        ...settings,
      ];
    }

    return settings;
  };

  return <div className='communication-node-settings'>{createSettings()}</div>;
};

export default observer(Settings);
