import React, { useContext } from 'react';
import { Notification } from '../../../../models/Utilities/Notification';
import { Text } from '../../../../models/Utilities/Text';
import { Utilities } from '../../../../models/Utilities/Utilities';
import rootStore from '../../../../stores/rootStore';
import Button from '../../../common/Button';
import ParagraphWithCtx from '../../../common/ParagraphWithCtx';
import { useGetIcon } from '../../../customHooks/useGetIcon';

interface IParagraphProps {
  activeSsml: boolean;
  setActiveSsml: (value: boolean) => void;
  message: Text;
  placeholder: string;
  removeHandler: () => void;
}

const MessageParagraph: React.FC<IParagraphProps> = ({
  activeSsml,
  setActiveSsml,
  message,
  placeholder,
  removeHandler,
}) => {
  const transformText = Utilities.truncateText(message.value, 80);
  const transformSsml = Utilities.truncateText(message.ssml, 80);
  const getIcon = useGetIcon();
  const activeClass = message.ssml ? 'active-icon' : '';
  const { dialogStore } = useContext(rootStore);

  const handleRemove = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    removeHandler();
  };

  const handleSsml = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setActiveSsml(true);
  };

  const handleText = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setActiveSsml(false);
  };

  const audioPlayback = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    const dialogId = dialogStore.currentlyEditedDialog?.dialogId;
    if (!dialogId) {
      new Notification({ text: 'Audio playback is not possible', type: 'error' });
    }

    const audioFile = await message.getAudio(dialogId!, activeSsml);
    if (audioFile) {
      const audio = document.createElement('audio');
      audio.src = audioFile;
      audio.play();
    }
  };

  const isCurrentMessagePropertyEmpty = () => {
    if (activeSsml) {
      return Utilities.isEmpty(message.ssml);
    } else {
      return Utilities.isEmpty(message.value);
    }
  };

  const messageEmpty = isCurrentMessagePropertyEmpty();

  return (
    <div className='message-paragraph-container'>
      {activeSsml ? (
        <>
          {!message.ssml ? (
            <span className='placeholder-span' style={{ fontStyle: 'italic' }}>
              {placeholder}
            </span>
          ) : (
            <ParagraphWithCtx text={transformSsml} />
          )}
        </>
      ) : (
        <>
          {!message.value ? (
            <span className='placeholder-span' style={{ fontStyle: 'italic' }}>
              {placeholder}
            </span>
          ) : (
            <ParagraphWithCtx text={transformText} />
          )}
        </>
      )}

      <div className='message-paragraph-container-buttons'>
        {activeSsml ? (
          <Button
            className='btn-borderless p-5'
            icon={getIcon('font')}
            clickHandler={handleText}
          />
        ) : (
          <Button
            tooltipPosition='top'
            tooltip={message.ssml ? 'Speech markup exists' : 'Speech markup exists'}
            className='btn-borderless p-5'
            icon={`${getIcon('sound')} ${activeClass}`}
            clickHandler={handleSsml}
          />
        )}
        <Button
          tooltipPosition='top'
          disabled={messageEmpty}
          tooltip={activeSsml ? 'Play back speech markup' : 'Play back text'}
          className='btn-borderless p-5'
          icon={getIcon('play')}
          clickHandler={audioPlayback}
        />
        <Button
          className='btn-remove btn-borderless p-5'
          icon={getIcon('remove')}
          clickHandler={handleRemove}
        />
      </div>
    </div>
  );
};

export default MessageParagraph;
