import { action, makeObservable } from 'mobx';
import { DialogBlockTypes } from '../../architecture/enums/DialogComponentType';
import NodeStore from '../../stores/NodeStore';
import { MessageDialogNode } from '../DialogNodes/MessageNodes/MessageDialogNode';
import { RootNode } from '../DialogNodes/RootNode';
import { Position } from '../Utilities/Position';
import { Text } from '../Utilities/Text';
import { DialogBlock } from './DialogBlock';

export class DefaultDialogBlock extends DialogBlock {
  type = DialogBlockTypes.DefaultDialogBlock;
  constructor() {
    super();
    this.position = new Position(10, 10);
    this.title = 'Default Block';
    this.userModificationAllowed = false;

    makeObservable(this, {
      createDefaultNode: action,
    });
  }

  createDefaultNode() {
    // Create the default node inside here should be reconsidered.
    const rootNode = new RootNode(this);
    NodeStore.getInstance().append(rootNode);

    const node = new MessageDialogNode(this);

    node.messages = [new Text('Sorry, ich weiß das leider nicht.')];
    NodeStore.getInstance().append(node);
  }
}
