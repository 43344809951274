import { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import rootStore from '../../stores/rootStore';

export function useReadOnly() {
  const { appStore, knowledgebaseStore } = useContext(rootStore);
  const location = useLocation();

  const getReadOnly = () => {
    return (
      location.pathname.includes('history') &&
      (location.pathname.includes('designer') ||
        location.pathname.includes('knowledgebase'))
    );
  };

  const isSavingInProgress = appStore.savingInProgress;

  const knowledgebaseReadOnlyMode =
    knowledgebaseStore.removedSources.length !== 0 ||
    knowledgebaseStore.addedSources.length !== 0 ||
    appStore.savingInProgress ||
    (location.pathname.includes('history') &&
      location.pathname.includes('knowledgebase'));

  return {
    getReadOnly,
    isSavingInProgress,
    knowledgebaseReadOnlyMode,
  };
}
